<template>
  <v-container
    grid-list-lg
    fluid
  >
    <AppHeader />
    <v-content fill-height>
      <v-layout
        row
        justify-center
      >
        <v-flex
          xs12
          md8
        >
          <v-layout
            row
            wrap
          >
            <v-flex xs12>
              <v-breadcrumbs
                class="pa-0"
                :items="breadcrumbItems"
                divider="/"
              />
            </v-flex>
            <template v-if="$vuetify.breakpoint.mdAndUp">
              <v-flex align-self-center>
                <h1 class="card-title display-2">Team Members</h1>
              </v-flex>
              <v-flex
                align-self-center
                shrink
              >
                <v-btn
                  color="primary"
                  block
                  large
                  dark
                  @click="teamInviteDialog = true"
                >
                  <v-icon left>add</v-icon>
                  <span class="button-label">Invite</span>
                </v-btn>
              </v-flex>
              <v-flex
                align-self-center
                shrink
              >
                <v-btn
                  block
                  large
                  dark
                  @click="leaveTeamDialog = true"
                >
                  <span class="button-label">Leave</span>
                </v-btn>
              </v-flex>
            </template>
            <template v-else>
              <v-flex xs12>
                <h1
                  v-resize-text
                  class="card-title font-weight-light"
                >
                  Team Members
                </h1>
              </v-flex>
              <v-flex xs12>
                <v-btn
                  color="primary"
                  block
                  large
                  dark
                  @click="teamInviteDialog = true"
                >
                  <v-icon left>add</v-icon>
                  <span class="button-label">Invite</span>
                </v-btn>
              </v-flex>
              <v-flex xs12>
                <v-btn
                  block
                  large
                  dark
                  @click="leaveTeamDialog = true"
                >
                  <span class="button-label">Leave</span>
                </v-btn>
              </v-flex>
            </template>
          </v-layout>
          <SearchBar
            v-model="searchText"
            @search="searchUserName"
          />
          <v-layout
            row
            wrap
          >
            <v-flex
              v-if="isLoading"
              x12
              class="d-flex justify-center align-center"
            >
              <v-progress-circular
                color="primary"
                indeterminate
                size="128"
              ></v-progress-circular>
            </v-flex>
            <v-flex
              v-else
              xs12
              py-0
            >
              <v-data-table
                :headers="headers"
                :items="users"
                :rows-per-page-items="[15, 50, 100]"
                class="elevation-3"
                :search="searchText"
              >
                <template v-slot:items="props">
                  <td>{{ props.item.name }}</td>
                  <td>{{ props.item.email }}</td>
                  <td class="capitalize">{{ props.item.role }}</td>
                  <td>{{ $n(props.item.total) || 0 }}</td>
                </template>
              </v-data-table>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-content>
    <TeamInviteDialog
      ref="teamInviteDialog"
      v-model="teamInviteDialog"
      :team-id="teamId"
      :team-name="teamName"
      @close="teamInviteDialog = false"
    />
    <ConfirmDeleteDialog
      ref="leaveTeamDialog"
      v-model="leaveTeamDialog"
      title="Leave Team"
      text="Are you sure you want to leave this team?"
      delete-title="Leave"
      @cancel-delete="leaveTeamDialog = false"
      @confirm-delete="requestLeaveTeam()"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AppHeader from "@/components/AppHeader";
import ConfirmDeleteDialog from "@/components/global/ConfirmDeleteDialog";
import SearchBar from "@/components/SearchBar";
import TeamInviteDialog from "@/components/TeamInviteDialog";
import ResizeText from "vue-resize-text";

export default {
  name: "Team",
  components: {
    AppHeader,
    ConfirmDeleteDialog,
    SearchBar,
    TeamInviteDialog,
  },
  directives: {
    ResizeText,
  },
  props: {
    teamId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: "Name", value: "name" },
        { text: "E-mail", value: "email" },
        { text: "Role", value: "role" },
        { text: "Contacts Imported", value: "total" },
      ],
      isLoading: false,
      leaveTeamDialog: false,
      searchText: "",
      teamInviteDialog: false,
    };
  },
  computed: {
    ...mapGetters({
      getUsers: "teams/getUsers",
      profile: "getProfile",
      teamStats: "statistics/getTeamStats",
    }),

    breadcrumbItems() {
      return [
        { text: "Teams", href: "/teams" },
        { text: this.teamStats.name, href: `/teams/${this.teamId}` },
      ];
    },

    teamName() {
      if (this.teamStats && this.teamStats.name) {
        return this.teamStats.name;
      }
      return "";
    },

    users() {
      let users = this.getUsers(this.teamId);
      let tableData = [];

      if (users == undefined || this.teamStats.users == undefined) {
        return [];
      }

      let mergedUsers = {};
      users.map((user) => {
        mergedUsers[user.name] = user;
      });
      this.teamStats.users.map((user) => {
        if (mergedUsers[user.name] == undefined) {
          mergedUsers[user.name] = user;
        }
        mergedUsers[user.name]["total"] = user.total_imported;
      });

      for (var user of Object.entries(mergedUsers)) {
        tableData.push({
          name: user[0],
          email: user[1].email,
          role: user[1].role,
          total: user[1].total,
        });
      }

      return tableData;
    },
  },
  mounted() {
    this.loadTeamData();
  },
  methods: {
    ...mapActions({
      fetchUsers: "teams/fetchUsers",
      fetchTeamStats: "statistics/fetchTeamStats",
      leaveTeam: "teams/leaveTeam",
    }),
    loadTeamData() {
      this.isLoading = true;
      Promise.all([
        this.fetchUsers({ teamId: this.teamId }),
        this.fetchTeamStats({ teamId: this.teamId }),
      ]).finally(() => {
        this.isLoading = false;
      });
    },
    requestLeaveTeam() {
      this.leaveTeam({ teamId: this.teamId, userId: this.profile.id })
        .then(() => {
          this.$router.push("/teams");
        })
        .error((_error) => {
          // Nothing yet.
        });
    },

    searchUserName(searchText) {
      this.searchText = searchText;
    },
  },
};
</script>

<style lang="scss">
.capitalize {
  text-transform: capitalize;
}
</style>
