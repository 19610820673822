<template>
  <v-container
    grid-list-lg
    fluid
  >
    <AppHeader />
    <v-content fill-height>
      <v-layout
        row
        justify-center
      >
        <v-flex
          xs12
          md8
        >
          <v-layout
            row
            wrap
          >
            <template v-if="$vuetify.breakpoint.mdAndUp">
              <v-flex align-self-center>
                <h1 class="card-title display-2">Teams</h1>
              </v-flex>
              <v-flex shrink>
                <v-btn
                  color="primary"
                  block
                  large
                  @click="newTeamDialog = true"
                >
                  <v-icon left>add</v-icon>
                  <span class="button-label">Create Team</span>
                </v-btn>
              </v-flex>
            </template>
            <template v-else>
              <v-flex xs12>
                <h1
                  v-resize-text
                  class="card-title font-weight-light"
                >
                  Teams
                </h1>
              </v-flex>
              <v-flex xs12>
                <v-btn
                  color="primary"
                  block
                  large
                  @click="newTeamDialog = true"
                >
                  <v-icon left>add</v-icon>
                  <span class="button-label">Create Team</span>
                </v-btn>
              </v-flex>
            </template>
            <v-flex
              xs12
              sm10
            >
              <SearchBar
                v-model="searchText"
                @search="searchTeam"
              />
            </v-flex>
            <v-flex
              xs12
              sm2
            >
              <v-menu
                offset-y
                open-on-hover
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="elevation-0"
                    color="primary"
                    flat
                    block
                    large
                    v-on="on"
                  >
                    <v-icon left>menu</v-icon>
                    Sort By
                  </v-btn>
                </template>
                <v-list class="py-0">
                  <v-list-tile
                    v-for="(sort, index) in sortChoices"
                    :key="index"
                    @click="sortTeams(sort.attr, sort.order)"
                  >
                    <v-list-tile-content>
                      <v-list-tile-title>{{ sort.text }}</v-list-tile-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <span
                        class="uppercase small-text primary--text text-xs-right"
                      >
                        {{ sort.order }}
                      </span>
                    </v-list-tile-action>
                  </v-list-tile>
                </v-list>
              </v-menu>
            </v-flex>
            <v-flex
              v-if="isLoading"
              x12
              class="d-flex justify-center align-center"
            >
              <v-progress-circular
                color="primary"
                indeterminate
                size="128"
              ></v-progress-circular>
            </v-flex>
            <v-flex
              v-else
              xs12
              pt-0
            >
              <v-card>
                <v-list
                  two-line
                  class="py-0"
                >
                  <template v-for="(team, index) in teams">
                    <v-divider
                      v-if="index > 0"
                      :key="index"
                    />
                    <v-list-tile
                      :key="team[0]"
                      @click="selectTeam(team[0])"
                    >
                      <v-list-tile-avatar>
                        <v-icon>people</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title>
                          {{ team[1].name }}
                        </v-list-tile-title>
                      </v-list-tile-content>
                      <v-list-tile-action>
                        <v-chip>Members: {{ team[1].totalUsers || 0 }}</v-chip>
                      </v-list-tile-action>
                      <v-list-tile-action>
                        <v-chip>
                          Total Contacts:
                          {{ $n(team[1].totalContacts) || 0 }}
                        </v-chip>
                      </v-list-tile-action>
                      <v-list-tile-action>
                        <v-icon>chevron_right</v-icon>
                      </v-list-tile-action>
                    </v-list-tile>
                  </template>
                </v-list>
              </v-card>
            </v-flex>
            <v-flex
              v-if="needsPagination"
              xs12
              my-3
            >
              <div class="text-xs-center">
                <v-pagination
                  v-model="pagination.currentPage"
                  :total-visible="7"
                  :length="Math.ceil(totalTeams / pagination.teamsPerPage)"
                  prev-icon="chevron_left"
                  next-icon="chevron_right"
                />
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-content>
    <NewTeamDialog
      ref="newTeamDialog"
      v-model="newTeamDialog"
      @create="addTeam($event)"
      @close="newTeamDialog = false"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AppHeader from "@/components/AppHeader";
import NewTeamDialog from "@/components/NewTeamDialog";
import ResizeText from "vue-resize-text";
import SearchBar from "@/components/SearchBar";

export default {
  name: "Teams",
  directives: {
    ResizeText,
  },
  components: {
    AppHeader,
    NewTeamDialog,
    SearchBar,
  },
  data() {
    return {
      dialog: false,
      isLoading: false,
      newTeamDialog: false,
      pagination: {
        descending: false,
        currentPage: 1,
        teamsPerPage: 10,
        sortBy: "name",
      },
      searchText: "",
      sortChoices: [
        { text: "Team Name", attr: "name", order: "asc" },
        { text: "Team Name", attr: "name", order: "desc" },
        { text: "Total Members", attr: "total_users", order: "asc" },
        { text: "Total Members", attr: "total_users", order: "desc" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      accountMngTeams: "teams/getTeams",
      profile: "getProfile",
      totalTeams: "teams/getTeamCount",
      userStats: "statistics/getUserStats",
    }),
    needsPagination() {
      return this.totalTeams && this.totalTeams > 10;
    },
    teams() {
      if (!Array.isArray(this.accountMngTeams)) {
        return [];
      }
      return this.mergeTeams(this.accountMngTeams, this.userStats.accounts);
    },
  },
  watch: {
    pagination: {
      handler() {
        this.fetchTeamsRequest();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchTeamsRequest();
    this.fetchUserStats({ userId: this.profile.id });
  },
  methods: {
    ...mapActions({
      fetchTeams: "teams/fetchTeams",
      fetchUserStats: "statistics/fetchUserStats",
    }),
    addTeam(resp) {
      if (resp.success == false) {
        this.$notify({
          group: "notifs",
          title: "Request Failed",
          text: resp.error,
          type: "error",
        });
      } else {
        this.$notify({
          group: "notifs",
          title: "Success",
          text: "Your team has been successfully created",
          type: "success",
        });
        this.fetchTeamsRequest();
      }
    },
    fetchTeamsRequest() {
      this.isLoading = true;

      let { teamsPerPage, sortBy, descending } = this.pagination;
      const offset = this.offset();

      let params = {
        dir: descending ? "desc" : "asc",
        limit: teamsPerPage,
        offset: offset,
        sort_by: sortBy,
      };

      if (this.searchText && this.searchText !== "") {
        params.q = this.searchText;
      }

      this.fetchTeams({ params: params }).finally(() => {
        this.isLoading = false;
      });
    },
    mergeTeams(accMngTeams, conMngTeams) {
      let accountTeams = accMngTeams || [];
      let contactTeams = conMngTeams || [];
      let mergedTeams = {};

      accountTeams.map((team) => {
        let t = mergedTeams[team.id] || {};
        mergedTeams[team.id] = Object.assign(t, { name: team.name });
      });
      contactTeams
        .filter((team) => {
          return mergedTeams.hasOwnProperty(team.account_id);
        })
        .map((team) => {
          let t = mergedTeams[team.account_id] || {};
          mergedTeams[team.account_id] = Object.assign(t, {
            totalContacts: team.total_imported || 0,
          });
          mergedTeams[team.account_id] = Object.assign(t, {
            totalUsers: team.total_users || 0,
          });
        });

      return Object.entries(mergedTeams);
    },
    offset() {
      const { currentPage, teamsPerPage } = this.pagination;
      return (currentPage - 1) * teamsPerPage;
    },
    searchTeam(searchText) {
      this.searchText = searchText;
      this.fetchTeamsRequest();
    },
    selectTeam(teamId) {
      this.$router.push(`/teams/${teamId}`);
    },
    sortTeams(sortSelection, sortOrder) {
      Object.assign(this.pagination, {
        sortBy: sortSelection,
        descending: sortOrder === "desc",
      });
    },
  },
};
</script>

<style lang="scss">
.uppercase {
  text-transform: uppercase;
}

.small-text {
  font-size: 9px;
}
</style>
