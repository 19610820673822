<template>
  <v-dialog
    v-model="open"
    width="290"
    style="overflow: auto; -webkit-overflow-scrolling: touch"
    @keydown.esc="cancelMerge"
  >
    <v-card class="pa-2">
      <v-card-title
        class="card-title"
        v-text="title"
      />
      <v-card-text v-text="text" />
      <ul class="pb-2">
        <li
          v-for="(item, index) in connectionNames"
          :key="index"
        >
          {{ item }}
        </li>
      </ul>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="!loading"
          ref="mergeButton"
          color="primary"
          class="elevation-0"
          dark
          @click.native="confirmMerge"
          @click="mergeClicked = true"
        >
          {{ mergeTitle }}
        </v-btn>
        <v-progress-linear
          v-if="loading"
          color="primary"
          class="mx-3"
          :indeterminate="true"
        />
        <v-btn
          v-if="!loading"
          ref="cancelButton"
          color="primary"
          outline
          @click.native="cancelMerge"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmMergeDialog",
  props: {
    connectionNames: {
      type: Array,
      default: () => [],
    },
    mergeTitle: {
      type: String,
      default: "Merge",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "Are you sure you want to merge?",
    },
    title: {
      type: String,
      default: "Merge",
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      open: this.value,
    };
  },
  watch: {
    value: {
      handler() {
        this.open = this.value;
      },
    },
    open: {
      handler() {
        if (this.open) {
          return;
        }
        this.cancelMerge();
      },
    },
  },
  methods: {
    cancelMerge() {
      this.$emit("cancel-merge");
    },
    confirmMerge() {
      this.$emit("confirm-merge");
    },
  },
};
</script>
