<template>
  <v-card class="transparent elevation-0">
    <MultilineChip class="mx-0">{{ totalConnectionText }}</MultilineChip>
    <v-list class="transparent">
      <v-list-tile @click="removeAllFilters">
        <v-icon left>view_list</v-icon>
        All Connections
      </v-list-tile>
      <v-list-tile @click="addOnlyMeFilter">
        <v-icon left>account_circle</v-icon>
        My Connections
      </v-list-tile>
    </v-list>
    <v-divider />
    <v-card-text class="px-2">
      <template v-if="showAdvancedSearch">
        <FilterChip
          v-for="(filter, index) in filters"
          :id="index"
          :key="index"
          :filter="filter"
          @remove="remove(index)"
        />
      </template>
      <AddSimpleFilter
        v-if="showSimpleSearch"
        @add-simple-filter="addSimpleFilter($event)"
        @toggle-search-mode="toggleSearchMode()"
        @clear-filter="removeAllFilters"
      />
      <AddFilter
        v-if="showAdvancedSearch"
        @add-filter="addFilter($event)"
        @toggle-search-mode="toggleSearchMode()"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import AddFilter from "@/components/filters/AddFilter";
import AddSimpleFilter from "@/components/filters/AddSimpleFilter";
import FilterChip from "@/components/filters/FilterChip";
import MultilineChip from "@/components/global/MultilineChip";

export default {
  name: "FilterCard",
  components: {
    AddFilter,
    FilterChip,
    MultilineChip,
    AddSimpleFilter,
  },
  props: {
    filters: {
      type: Array,
      default: function () {
        return [];
      },
    },
    totalConnections: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showSimpleSearch: true,
      showAdvancedSearch: false,
    };
  },
  computed: {
    ...mapGetters({
      profile: "getProfile",
    }),
    totalConnectionText() {
      switch (this.totalConnections) {
        case 0:
          return "No connections found.";
        case 1:
          return "1 connection found.";
        default:
          return `${this.$n(this.totalConnections)} connections found.`;
      }
    },
  },
  methods: {
    addFilter(filter) {
      this.$emit("add-advanced", filter);
    },
    addSimpleFilter(filters) {
      this.$emit("add-simple", filters);
    },
    addOnlyMeFilter() {
      let filter = {
        name: "user_id",
        nameLabel: "User",
        where: "IS",
        whereLabel: "is",
        value: this.profile.id,
        valueLabel: this.profile.name,
      };

      this.$emit("remove-all-filters");
      this.$emit("add-advanced", filter);
      this.showSimpleSearch = false;
      this.showAdvancedSearch = true;
    },
    remove(index) {
      this.$emit("remove", { index: index });
    },
    removeAllFilters() {
      this.$emit("remove-all-filters");
    },
    toggleSearchMode() {
      if (this.showAdvancedSearch) {
        this.removeAllFilters();
      }
      this.showSimpleSearch = !this.showSimpleSearch;
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
  },
};
</script>
