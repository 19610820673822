<template>
  <v-card
    width="100%"
    class="hover-card"
    @click="$emit('selected')"
  >
    <v-layout
      row
      fill-height
      align-center
    >
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <v-flex
          shrink
          ml-3
          my-3
        >
          <ScoreIndicator
            v-model="highScore"
            :size="avatarHeight"
            :width="scoreStroke"
          />
        </v-flex>
        <v-flex
          shrink
          my-3
        >
          <Avatar
            :username="name"
            :src="photo"
            :size="avatarHeight"
          />
        </v-flex>
      </template>
      <v-flex
        v-else
        shrink
        my-3
        ml-3
      >
        <Avatar
          :username="name"
          :src="photo"
          :size="avatarHeight"
        />
      </v-flex>
      <v-flex class="ellipsis-text mr-3">
        <span class="headertext">{{ name }}</span>
        <template v-if="jobTitle != null && jobTitle !== ''">
          <br />
          <span class="subtext">{{ jobTitle }}</span>
        </template>
        <template v-if="company != null && company !== ''">
          <br />
          <v-icon
            class="mr-1"
            small
          >
            domain
          </v-icon>
          <span class="subtext">{{ company }}</span>
        </template>
      </v-flex>
      <v-flex shrink>
        <v-checkbox
          v-if="selectable"
          :input-value="checkbox"
          class="px-3 ma-0"
          color="primary"
          @click.stop="checkbox = !checkbox"
          @click="$emit('merge-list')"
        />
      </v-flex>
    </v-layout>
    <v-card-title class="grey lighten-5">
      <ScoreChip
        v-for="(user, index) in knownByTop"
        :key="index"
        v-model="user.score"
        :name="user.name"
      />
      <span
        v-if="knownByOtherTotal > 0"
        class="primary--text pa-1"
        v-text="knownByOtherText"
      />
    </v-card-title>
  </v-card>
</template>

<script>
import Avatar from "vue-avatar";
import ScoreChip from "@/components/connection/ScoreChip";
import ScoreIndicator from "@/components/connection/ScoreIndicator";

export default {
  name: "ConnectionCard",
  components: {
    Avatar,
    ScoreChip,
    ScoreIndicator,
  },
  props: {
    contact: {
      type: Object,
      required: true,
    },
    selectable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      checkbox: false,
      scoreChipCutoff: 1,
    };
  },
  computed: {
    avatarHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 44;
        case "sm":
          return 44;
        case "md":
          return 64;
        case "lg":
          return 64;
        case "xl":
          return 64;
        default:
          return 64;
      }
    },
    company() {
      let data = this.contact.info.filter((x) =>
        ["work_experience", "job_title"].includes(x.type)
      );
      return this.prioritizeCompanies(data);
    },
    highScore() {
      return this.knownBy[0].score;
    },
    jobTitle() {
      let data = this.contact.info.filter((x) =>
        ["work_experience", "job_title"].includes(x.type)
      );
      return this.prioritizeJobTitles(data);
    },
    knownBy() {
      let knownBy = [...this.contact.scores];
      return knownBy.sort((a, b) => b.score - a.score);
    },
    knownByTop() {
      let knownBy = [...this.contact.scores];
      return knownBy
        .sort((a, b) => b.score - a.score)
        .slice(0, this.scoreChipCutoff);
    },
    knownByOtherText() {
      return `+${this.knownByOtherTotal} more`;
    },
    knownByOtherTotal() {
      return Math.max(this.contact.scores.length - this.scoreChipCutoff, 0);
    },
    name() {
      let data = this.contact.info.filter((x) => x.type == "name");
      return data.length == 0 ? "Not set." : data[0].value;
    },
    photo() {
      // Temporarily disabled until a sustainable solution to deal with expired Google profile images is found
      // let data = this.contact.info.filter((x) => x.type == "photo");
      // return data.length == 0 ? null : data[0].value;
      return null;
    },
    scoreStroke() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 5;
        case "sm":
          return 5;
        case "md":
          return 6;
        case "lg":
          return 6;
        case "xl":
          return 6;
        default:
          return 6;
      }
    },
  },
  methods: {
    bothExisted(job) {
      // check if both job title & company is existed
      const company = job.detail,
        title = job.value;
      if (!company || company === "") {
        return false;
      }
      if (!title || title === "") {
        return false;
      }
      return true;
    },
    prioritizeCompanies(data) {
      if (data.length == 0) {
        return null;
      }
      if (data.length == 1) {
        return data[0].detail;
      }

      for (const job in data) {
        if (this.bothExisted(job)) {
          return job.detail;
        }
      }
      return data[0].detail;
    },
    prioritizeJobTitles(data) {
      if (data.length == 0) {
        return null;
      }
      if (data.length == 1) {
        return data[0].value;
      }

      for (const job in data) {
        if (this.bothExisted(job)) {
          return job.value;
        }
      }
      return data[0].value;
    },
  },
};
</script>

<style lang="scss">
.hover-card {
  &:hover {
    transform: scale(1.03);
  }
}

.ellipsis-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.headertext {
  font-size: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.subtext {
  color: #444;
  font-size: 11px;
  min-width: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
