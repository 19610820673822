import Vue from "vue";
import Vuetify from "vuetify/lib";
import "vuetify/src/stylus/app.styl";

import "@fortawesome/fontawesome-free/css/all.css";

Vue.use(Vuetify, {
  iconfont: "fa",
  theme: {
    primary: "#dd4b39",
    secondary: "#3766fd",
    navy: "#12253e",
    darkNavy: "#0c1623",
  },
});
