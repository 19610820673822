<template>
  <div class="page-title">
    <h1
      v-if="$vuetify.breakpoint.mdAndUp"
      class="card-title display-2"
      v-text="value"
    />
    <h1
      v-else
      v-resize-text
      class="card-title font-weight-light"
      v-text="value"
    />
  </div>
</template>

<script>
import ResizeText from "vue-resize-text";

export default {
  name: "PageTitle",
  directives: {
    ResizeText,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
  },
};
</script>
