<template>
  <v-container
    grid-list-lg
    fluid
  >
    <AppHeader />
    <v-content fill-height>
      <v-layout row>
        <v-flex>
          <PageTitle value="Billing" />
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex>
          <v-tabs
            v-model="selectedTabIndex"
            color="transparent"
          >
            <v-tabs-slider color="black" />
            <v-tab
              v-for="tabText in tabs"
              :key="tabText"
              class="mr-3"
            >
              <span class="title font-weight-regular">{{ tabText }}</span>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="selectedTabIndex">
            <v-tab-item
              v-for="(tabText, i) in tabs"
              :key="i"
              class="pt-3"
            >
              <Invoices v-if="i == 0" />
              <PreviousPayments v-if="i == 1" />
              <Subscription
                v-if="i == 2"
                @update-subscription-page="updateSubscriptionPage"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-flex>
      </v-layout>
    </v-content>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import AppHeader from "@/components/AppHeader";
import Invoices from "@/components/billing/Invoices";
import PageTitle from "@/components/global/PageTitle";
import Subscription from "@/components/billing/Subscription";
import PreviousPayments from "@/components/billing/PreviousPayments";

export default {
  name: "Billing",
  components: {
    AppHeader,
    Invoices,
    PageTitle,
    Subscription,
    PreviousPayments,
  },
  data() {
    return {
      selectedTabIndex: null,
      tabs: ["Invoices", "Previous Payments", "Subscription"],
    };
  },
  mounted() {
    this.fetchInvoices();
    this.fetchPaymentMethod();
    this.fetchPayments();
  },
  methods: {
    ...mapActions({
      fetchInvoices: "payment/fetchInvoices",
      fetchPaymentMethod: "payment/fetchPaymentMethod",
      fetchPaymentProfile: "payment/fetchPaymentProfile",
      fetchPayments: "payment/fetchPayments",
    }),
    updateSubscriptionPage() {
      this.fetchPaymentProfile();
      this.selectedTabIndex = 2;
    },
  },
};
</script>
