export const EXISTS = "EXISTS";
export const NOT_EXISTS = "NOT_EXISTS";
export const MATCHES_EXACTLY = "MATCHES_EXACTLY";
export const CONTAINS = "CONTAINS";
export const NOT_CONTAINS = "NOT_CONTAINS";

export const IS_LESS_THAN = "IS_LESS_THAN";
export const IS_LESS_THAN_OR_EQUAL = "IS_LESS_THAN_OR_EQUAL";
export const IS_GREATER_THAN = "IS_GREATER_THAN";
export const IS_GREATER_THAN_OR_EQUAL = "IS_GREATER_THAN_OR_EQUAL";

export const IS = "IS";
export const IS_NOT = "IS_NOT";

export const IS_ONE_OF = "IS_ONE_OF";
export const IS_NOT_ONE_OF = "IS_NOT_ONE_OF";

export const typeOptions = {
  number: [
    IS_GREATER_THAN,
    IS_GREATER_THAN_OR_EQUAL,
    IS_LESS_THAN,
    IS_LESS_THAN_OR_EQUAL,
  ],
  string: [CONTAINS, NOT_CONTAINS, MATCHES_EXACTLY],
  team: [IS_ONE_OF, IS_NOT_ONE_OF],
  user: [
    IS,
    // IS_NOT
  ],
};

export const whereLabels = {
  CONTAINS: "contains",
  EXISTS: "exists",
  MATCHES_EXACTLY: "matches exactly",
  NOT_CONTAINS: "does not contain",
  NOT_EXISTS: "does not exist",
  IS: "is",
  IS_GREATER_THAN: "is greater than",
  IS_GREATER_THAN_OR_EQUAL: "is greater than or equal to",
  IS_LESS_THAN: "is less than",
  IS_LESS_THAN_OR_EQUAL: "is less than or equal to",
  IS_NOT: "is not",
  IS_NOT_ONE_OF: "is not one of",
  IS_ONE_OF: "is one of",
};

export const inputValueType = {
  CONTAINS: "string",
  MATCHES_EXACTLY: "string",
  NOT_CONTAINS: "string",
  IS: "user",
  IS_GREATER_THAN: "number",
  IS_GREATER_THAN_OR_EQUAL: "number",
  IS_LESS_THAN: "number",
  IS_LESS_THAN_OR_EQUAL: "number",
  IS_NOT: "user",
  IS_NOT_ONE_OF: "team",
  IS_ONE_OF: "team",
};

export default {
  needsValue(_where) {
    // Always return true for now
    return true;
  },
};
