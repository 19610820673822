<template>
  <v-progress-circular
    :value="compValue"
    :rotate="-90"
    :size="size"
    :width="width"
    :color="color"
    large
  >
    {{ compValue }}
  </v-progress-circular>
</template>

<script>
export default {
  name: "ScoreIndicator",
  props: {
    value: {
      type: Number,
      default: 0,
    },
    size: {
      type: Number,
      default: 80,
    },
    width: {
      type: Number,
      default: 10,
    },
  },
  computed: {
    compValue() {
      return Math.min(100, this.value);
    },
    color() {
      if (this.value > 80) {
        return "success";
      } else if (this.value > 50) {
        return "warning";
      } else {
        return "error";
      }
    },
  },
};
</script>
