<template>
  <v-card
    class="elevation-0 my-3"
    color="transparent"
  >
    <v-card-text class="text-xs-center">
      <v-avatar
        size="96"
        color="success"
      >
        <v-icon
          class="white--text"
          large
        >
          check
        </v-icon>
      </v-avatar>
      <h1 class="text-xs-center mt-3">Nice!</h1>
      <p class="text-xs-center">You're ready to start using 6dos.</p>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="primary"
        to="/connections"
      >
        Go To Connections
      </v-btn>
      <v-spacer />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "FinishedView",
};
</script>
