import Vue from "vue";
import Router from "vue-router";

import Billing from "./views/Billing.vue";
import Changelog from "./views/Changelog.vue";
import Connections from "./views/Connections.vue";
import FAQ from "./views/FAQ.vue";
import GettingStarted from "./views/GettingStarted.vue";
import Import from "./views/Import.vue";
import JoinTeam from "./views/JoinTeam.vue";
import Login from "./views/Login.vue";
import Profile from "./views/Profile.vue";
import ResetPassword from "./views/ResetPassword.vue";
import Signup from "./views/Signup.vue";
import Team from "./views/Team.vue";
import Teams from "./views/Teams.vue";

Vue.use(Router);

const hasToken = () => {
  return !!Vue.cookie.get("token");
};

const ifNotAuthenticated = (to, from, next) => {
  if (!hasToken()) {
    next();
    return;
  }
  next("/");
};

const ifAuthenticated = (to, from, next) => {
  if (hasToken()) {
    next();
    return;
  }

  const redirectTo = window.location.pathname;
  if (redirectTo === "/") {
    next({ name: "Signup" });
  } else {
    next({ name: "Signup", query: { from: redirectTo } });
  }
};

export default new Router({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: "/",
      redirect: "/connections",
    },
    {
      path: "/connections",
      name: "Connections",
      component: Connections,
      props: (route) => ({
        contactId: route.query.contactId || route.query.contact_id,
      }),
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/import",
      name: "Import",
      component: Import,
      props: true,
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/join-team/:token",
      name: "JoinTeam",
      component: JoinTeam,
      props: true,
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/teams",
      name: "Teams",
      component: Teams,
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/teams/:teamId",
      name: "Team",
      component: Team,
      props: true,
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/profile",
      name: "Profile",
      component: Profile,
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
      beforeEnter: ifNotAuthenticated,
    },
    {
      path: "/signup",
      name: "Signup",
      component: Signup,
      beforeEnter: ifNotAuthenticated,
    },
    {
      path: "/reset-password",
      name: "ResetPassword",
      component: ResetPassword,
      beforeEnter: ifNotAuthenticated,
    },
    {
      path: "/changelog",
      name: "Changelog",
      component: Changelog,
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/faq",
      name: "FAQ",
      component: FAQ,
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/getting-started",
      name: "GettingStarted",
      component: GettingStarted,
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/billing",
      name: "Billing",
      component: Billing,
      beforeEnter: ifAuthenticated,
    },
  ],
});
