<template>
  <v-dialog
    v-model="open"
    width="800"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-text>
        <h2 class="display-2 mb-3">Let's get started.</h2>
        <span class="subheading">
          Where do you usually store your contacts?
        </span>
      </v-card-text>
      <v-list
        two-line
        class="pb-0"
      >
        <template v-for="(item, i) in sources">
          <v-divider :key="i" />
          <v-list-tile
            :key="item.name"
            @click="beginTour(item.name)"
          >
            <v-list-tile-avatar
              class="ml-2"
              size="30"
              tile
            >
              <img :src="iconImg(item.name)" />
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title class="card-title">
                {{ item.name }}
              </v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-icon>chevron_right</v-icon>
            </v-list-tile-action>
          </v-list-tile>
        </template>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ImportTourDialog",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      open: this.value,
      sources: [
        { name: "Apple/vCard" },
        { name: "Exchange" },
        { name: "Google" },
        { name: "LinkedIn" },
        { name: "Office 365" },
      ],
    };
  },
  watch: {
    value: {
      handler() {
        this.open = this.value;
      },
    },
    open: {
      handler() {
        if (this.open) {
          return;
        }
        this.close();
      },
    },
  },
  methods: {
    close() {
      this.$emit("dismiss");
    },
    iconImg(source) {
      switch (source) {
        case "Apple/vCard":
          return require("@/assets/social/apple.png");
        case "Google":
          return require("@/assets/social/google.png");
        case "LinkedIn":
          return require("@/assets/social/linkedin.png");
        case "Office 365":
          return require("@/assets/social/office365.png");
        case "Exchange":
          return require("@/assets/social/exchange.png");
        default:
          return "";
      }
    },
    beginTour(source) {
      this.open = false;
      this.close();
      this.$emit("begin-tour", source);
    },
  },
};
</script>
