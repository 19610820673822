<template>
  <v-card
    class="elevation-0 navy"
    dark
  >
    <v-layout
      justify-end
      row
    >
      <v-flex
        height="100%"
        shrink
      >
        <v-btn
          icon
          small
          large
          dark
          class="ma-3"
          @click="$emit('close')"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-divider class="pb-2" />
    <v-layout
      fill-height
      align-top
      row
    >
      <v-flex height="100%">
        <v-sheet
          height="100%"
          class="navy"
          dark
        >
          <v-list class="py-0 navy">
            <v-list-tile
              class="py-2"
              avatar
              @click="goTo(`/profile`)"
            >
              <v-list-tile-avatar>
                <v-icon>account_circle</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title v-text="profileName" />
                <v-list-tile-sub-title v-text="profileEmail" />
                <v-list-tile-sub-title class="mobile-link">
                  Profile & Preferences
                </v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile
              v-if="needsToShowBill"
              avatar
              @click="goTo(`/billing`)"
            >
              <v-list-tile-avatar>
                <v-icon>payment</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title>Billing</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-divider class="my-2" />
            <v-list-tile
              avatar
              @click="goTo(`/connections`)"
            >
              <v-list-tile-avatar>
                <v-icon>contacts</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title>Connections</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile
              avatar
              @click="goTo(`/teams`)"
            >
              <v-list-tile-avatar>
                <v-icon>people</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title>Teams</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile
              avatar
              @click="goTo(`/import`)"
            >
              <v-list-tile-avatar>
                <v-icon>data_usage</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title>Build Your Score</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-divider class="my-2" />
            <v-list-tile
              v-if="needsToShowSubscribe"
              avatar
              @click="doSubscribe"
            >
              <v-list-tile-avatar>
                <v-icon color="yellow lighten-1">notifications</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title class="yellow--text text--lighten-1">
                  Subscribe
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile
              avatar
              @click="goTo(`/faq`)"
            >
              <v-list-tile-avatar>
                <v-icon color>help</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title>FAQ</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile
              avatar
              @click="requestLogout"
            >
              <v-list-tile-avatar>
                <v-icon>exit_to_app</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title>Log Out</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-sheet>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "MobileAppMenu",
  props: {
    profile: {
      type: Object,
      required: true,
    },
    needsToShowBill: {
      type: Boolean,
      required: true,
    },
    needsToShowSubscribe: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      paymentProfile: "payment/getPaymentProfile",
    }),
    profileName() {
      if (this.profile === null) {
        return "None";
      }
      return this.profile.name;
    },
    profileEmail() {
      if (this.profile === null) {
        return "None";
      }
      return this.profile.email;
    },
  },
  methods: {
    ...mapActions({
      logout: "logout",
    }),
    goTo(path) {
      this.$emit("close");
      this.$router.push(path);
    },
    doSubscribe() {
      this.$emit("do-subscribe");
    },
    requestLogout() {
      this.logout().then(() => {
        this.$emit("close");
        this.$router.push("/login");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-link {
  color: white !important;
}
</style>
