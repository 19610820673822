<template>
  <v-container
    grid-list-lg
    fluid
    @scroll="handleScroll"
  >
    <AppHeader />
    <v-content fill-height>
      <v-layout
        row
        wrap
        justify-start
      >
        <v-flex shrink>
          <PageTitle value="Connections" />
        </v-flex>
        <v-layout
          justify-end
          class="ma-0 pa-0"
        >
          <v-flex shrink>
            <v-btn
              v-if="showMergeButton"
              outline
              color="primary"
              @click="confirmMergeDialog = true"
            >
              merge
            </v-btn>
          </v-flex>
        </v-layout>
      </v-layout>
      <v-layout
        row
        wrap
      >
        <v-flex
          xs12
          sm12
          md3
          lg2
        >
          <FilterCard
            :filters="filters"
            :total-connections="totalConnections"
            @add-advanced="addFilter($event)"
            @add-simple="addSimpleFilter($event)"
            @remove="removeFilter($event.index)"
            @remove-all-filters="clearFilters"
          />
        </v-flex>
        <v-flex
          v-if="totalConnections > 0"
          xs12
          sm12
          md9
          lg10
        >
          <v-layout
            row
            wrap
            class="pt-2"
          >
            <v-flex
              v-for="contact in connections"
              :key="contact.id"
              xs12
              sm6
              md6
              lg4
            >
              <ConnectionCard
                :key="contact.id"
                :contact="contact"
                :selectable="selectable"
                @merge-list="mergeList(contact)"
                @selected="selectConnection(contact)"
              />
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex
          v-else
          xs12
          sm8
          md9
          lg10
        >
          <v-layout
            row
            wrap
            fill-height
            justify-center
            align-center
          >
            <v-flex
              v-if="isLoading"
              x12
              class="d-flex justify-center align-center"
            >
              <v-progress-circular
                color="primary"
                indeterminate
                size="128"
              ></v-progress-circular>
            </v-flex>
            <v-flex
              v-else
              class="text-xs-center grey--text"
              xs12
              md7
              lg5
              xl4
            >
              <v-icon
                color="grey"
                size="96"
              >
                perm_identity
              </v-icon>
              <br />
              <p
                v-if="$vuetify.breakpoint.mdAndUp"
                class="display-3 font-weight-thin font-italic"
              >
                No Connections
              </p>
              <p
                v-else
                class="display-1 font-weight-thin font-italic"
              >
                No Connections
              </p>
              <v-btn
                class="mt-4"
                color="primary"
                large
                block
                @click="$router.push('/import')"
              >
                Import Contacts
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-layout>
          <v-flex v-if="false">
            <v-btn
              v-if="totalConnections > 0"
              color="primary"
              large
              block
              @click="searchConnections()"
            >
              More Connections
            </v-btn>
          </v-flex>
        </v-layout>
      </v-layout>
    </v-content>
    <ConnectionDetailDialog
      v-model="connectionDetailDialog"
      :connection="selectedConnection"
      @delete-connection="deleteConnection($event)"
      @close="closeConnectionDetail"
    />
    <ConfirmMergeDialog
      v-model="confirmMergeDialog"
      :connection-names="selectedConnectionsNames"
      :loading="mergeLoading"
      title="Confirm Merge"
      text="Are you sure you want to merge these connections?"
      @confirm-merge="completeMerge"
      @cancel-merge="confirmMergeDialog = false"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import API from "@/api/contacts";
import AppHeader from "@/components/AppHeader";
import ConnectionCard from "@/components/connection/ConnectionCard";
import ConnectionDetailDialog from "@/components/connection/ConnectionDetailDialog";
import ConfirmMergeDialog from "@/components/global/ConfirmMergeDialog";
import FilterCard from "@/components/filters/FilterCard";
import PageTitle from "@/components/global/PageTitle";
import Vue from "vue";

export default {
  name: "Connections",

  components: {
    AppHeader,
    ConnectionCard,
    ConnectionDetailDialog,
    ConfirmMergeDialog,
    FilterCard,
    PageTitle,
  },

  props: {
    contactId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      confirmMergeDialog: false,
      connectionDetailDialog: false,
      connections: [],
      isLoading: false,
      limit: 30,
      mergeLoading: false,
      offset: 0,
      search: "",
      selectedConnection: { name: "Not Set." },
      selectedConnections: [],
      selectedConnectionsNames: [],
      showMergeButton: false,
      totalConnections: 0,
    };
  },

  computed: {
    ...mapGetters({
      filters: "getActiveFilters",
      needsGettingStarted: "statistics/needsTourDialog",
      profile: "getProfile",
    }),

    selectable() {
      if (!this.profile) {
        return false;
      }
      return ["super_admin"].includes(this.profile.role);
    },
  },
  watch: {
    connections: function (val) {
      this.offset = val.length;
    },

    filters: {
      handler() {
        this.searchInitialConnections();
      },
    },

    needsGettingStarted: {
      handler() {
        if (!this.needsGettingStarted) {
          return;
        }
        this.$router.push("/getting-started");
      },
    },

    profile() {
      if (this.profile.id) {
        this.fetchUserStats({ userId: this.profile.id });
      }
    },
  },

  mounted() {
    this.checkDeepLink();
    this.searchInitialConnections();
    window.addEventListener("scroll", this.handleScroll);

    if (this.needsGettingStarted) {
      this.$router.push("/getting-started");
    }
  },

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    ...mapActions({
      updateActiveFilters: "updateActiveFilters",
      fetchUserStats: "statistics/fetchUserStats",
    }),

    addFilter(filter) {
      let filters = [...this.filters];
      filters.push(filter);
      this.updateActiveFilters({ filters: filters });
    },

    addSimpleFilter(filters) {
      let currentFilters = [...this.filters];
      filters.map((filter) => {
        currentFilters.push(filter);
      });
      this.updateActiveFilters({ filters: filters });
    },

    checkDeepLink() {
      if (this.contactId) {
        let filter = {
          name: "contact_id",
          nameLabel: "Contact",
          where: "IS",
          whereLabel: "is",
          value: this.contactId,
          valueLabel: this.contactId,
        };

        this.addFilter(filter);
      }
    },

    clearFilters() {
      this.updateActiveFilters({ filters: [] });
    },

    closeConnectionDetail() {
      this.connectionDetailDialog = false;
      Vue.set(this, "selectedConnection", { name: "Not Set." });
    },

    completeMerge() {
      API.mergeContacts(this.selectedConnections)
        .then((_resp) => {
          setTimeout(() => {
            this.confirmMergeDialog = false;
            this.selectedConnections = [];
            this.selectedConnectionsNames = [];
            this.showMergeButton = false;
            this.mergeLoading = false;
            this.searchInitialConnections();
            this.$notify({
              group: "notifs",
              title: "Merge Successful",
              text: "Successfully merged contacts!",
              type: "success",
            });
          }, 5000);
        })
        .catch((_error) => {
          this.mergeLoading = false;
          this.$notify({
            group: "notifs",
            title: "Merge Failed",
            text: "Failed to merge!",
            type: "error",
          });
        });
      this.mergeLoading = true;
    },

    deleteConnection(connectionId) {
      let index = this.connections.findIndex(
        (c) => c.contact_id == connectionId && c.user_id == this.profile.id
      );
      var connection = this.connections[index];
      connection.scores = connection.scores.filter(
        (s) => s.user_id != this.profile.id
      );
      connection.sources = [];
      connection.info = connection.info.filter((x) =>
        ["name", "work_experience", "job_title"].includes(x.type)
      );

      if (connection.scores.length > 0) {
        this.$set(this.connections, index, connection);
      } else {
        this.deleteConnectionAtIndex(index);
      }

      this.connectionDetailDialog = false;
      this.selectedConnection = { name: "Not Set." };
    },

    deleteConnectionAtIndex(index) {
      this.connections.splice(index, 1);
      this.totalConnections -= 1;
      this.offset -= 1;
    },

    handleScroll() {
      let bottomOfWindow =
        document.documentElement.scrollTop + window.innerHeight + 500 >=
        document.documentElement.offsetHeight;

      if (bottomOfWindow) {
        this.searchConnections();
      }
    },

    removeFilter(index) {
      let filters = [...this.filters];
      filters.splice(index, 1);
      this.updateActiveFilters({ filters: filters });
    },

    searchConnections() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      var params = {
        limit: this.limit,
        offset: this.offset,
        filters: this.filters,
      };

      API.searchContacts(params)
        .then((resp) => {
          this.connections = this.connections.concat(resp.data);
          this.totalConnections = resp.total;
          // Automatically open the connection detail if there is only one connection
          if (resp.total === 1) {
            this.selectConnection(this.connections[0]);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    searchInitialConnections() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.connections = [];
      this.offset = 0;

      const params = {
        limit: this.limit,
        offset: this.offset,
        filters: this.filters,
      };

      API.searchContacts(params)
        .then((resp) => {
          this.$set(this, "connections", resp.data);
          this.totalConnections = resp.total;
          // Automatically open the connection detail if there is only one connection
          if (resp.total === 1) {
            this.selectConnection(this.connections[0]);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    selectConnection(connection) {
      this.selectedConnection = connection;
      this.connectionDetailDialog = true;
    },

    mergeList(connection) {
      let contactId = connection.contact_id;
      let connectionName = this.retrieveName(connection);

      if (this.selectedConnections.includes(contactId)) {
        this.selectedConnections = this.removeValue(
          this.selectedConnections,
          contactId
        );
        this.selectedConnectionsNames = this.removeValue(
          this.selectedConnectionsNames,
          connectionName
        );
      } else {
        this.selectedConnections.push(contactId);
        this.selectedConnectionsNames.push(connectionName);
      }

      this.showMergeButton = this.selectedConnections.length >= 2;
    },

    removeValue(arr, value) {
      return arr.filter(function (ele) {
        return ele != value;
      });
    },

    retrieveName(contact) {
      let data = contact.info.filter((x) => x.type == "name");
      return data.length == 0 ? "Not set." : data[0].value;
    },
  },
};
</script>
