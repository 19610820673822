import Vue from "vue";

export default {
  getAccounts: (state) => state.accountIds.map((id) => state.accounts[id]),

  getActiveFilters: (state) => state.activeFilters,

  getAccount: (state) => (id) => state.accounts[id] || {},

  getProfile: (state) => state.profile || {},

  getSelectedAccount: (state) => state.selectedAccount,

  getTotalAccounts: (state) => state.accountIds.length,

  hasAccounts: (state) => !(state.accountIds.length === 0),

  isAuthenticated: () => !!Vue.cookie.get("token"),

  isPrivileged: (state) =>
    ["super_admin", "admin", "agent"].includes((state.profile || {}).role),

  isSuperAdmin: (state) => (state.profile || {}).role === "super_admin",
};
