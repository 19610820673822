import * as types from "./mutation-types";
import API from "@/api";
import AccountApi from "@/api/accounts";

export default {
  login({ commit }, { email, password }) {
    return new Promise((resolve, reject) => {
      commit(types.LOGIN_REQUEST);
      API.login(email, password)
        .then((resp) => {
          const token = resp.token;
          commit(types.LOGIN_SUCCESS, { email: email, token: token });
          resolve(resp);
        })
        .catch((error) => {
          var msg = "";

          if (error.response === undefined) {
            msg = "Something went wrong.";
            commit(types.LOGIN_FAILURE, { msg });
            reject(msg);
            return;
          }

          switch (error.response.status) {
            case 401:
            case 404:
              msg = "Invalid email/password.";
              break;

            case 403:
              msg = "Account disabled.";
              break;

            default:
              msg = "Something went wrong.";
              break;
          }

          commit(types.LOGIN_FAILURE, { msg });
          reject(msg);
        });
    });
  },

  oauth({ commit }, { provider, code, type, redirectUri }) {
    return new Promise((resolve, reject) => {
      commit(types.LOGIN_REQUEST);

      API.auth({
        provider: provider,
        code: code,
        type: type,
        redirectUri: redirectUri,
      })
        .then((resp) => {
          if (type === "register") {
            commit(types.STATS_NEEDS_TOUR);
          }

          const { token, email } = resp;
          commit(types.LOGIN_SUCCESS, { email: email, token: token });
          resolve(resp);
        })
        .catch((error) => {
          var msg = "";

          if (error.response === undefined) {
            msg = "Something went wrong.";
            commit(types.LOGIN_FAILURE, { msg });
            reject(msg);
            return;
          }

          switch (error.response.status) {
            case 401:
            case 404:
              msg = "Account does not exist.";
              break;

            case 403:
              msg = "Account disabled.";
              break;

            default:
              msg = "Something went wrong.";
              break;
          }

          commit(types.LOGIN_FAILURE, { msg });
          reject(msg);
        });
    });
  },

  register({ commit }, { email, name, password }) {
    return new Promise((resolve, reject) => {
      commit(types.LOGIN_REQUEST);
      API.register(name, email, password)
        .then((resp) => {
          commit(types.STATS_NEEDS_TOUR);

          const token = resp.token;
          commit(types.LOGIN_SUCCESS, { email: email, token: token });
          resolve(resp);
        })
        .catch((error) => {
          var msg = error.response.data.error.message;
          commit(types.LOGIN_FAILURE, { msg });
          reject(msg);
        });
    });
  },

  logout({ commit }) {
    return new Promise((resolve, _reject) => {
      commit(types.PAYMENT_RESET_STATE);
      commit(types.STATS_RESET_STATE);
      commit(types.TEAMS_RESET_STATE);
      commit(types.LOGOUT);

      resolve();
    });
  },

  requestPasswordReset({ _commit }, { email }) {
    return new Promise((resolve, reject) => {
      API.requestPasswordReset(email)
        .then((_) => resolve())
        .catch((error) => reject(error));
    });
  },

  changePassword({ _commit }, { token, password }) {
    return new Promise((resolve, reject) => {
      API.changePassword(token, password)
        .then((_) => resolve())
        .catch((error) => reject(error));
    });
  },

  userChangePassword({ _commit }, { oldPassword, newPassword }) {
    return new Promise((resolve, reject) => {
      API.userChangePassword(oldPassword, newPassword)
        .then((_) => resolve())
        .catch((error) => reject(error));
    });
  },

  fetchProfile({ commit }) {
    return new Promise((resolve, reject) => {
      commit(types.FETCH_PROFILE_REQUEST);

      API.fetchProfile()
        .then((resp) => {
          commit(types.FETCH_PROFILE_SUCCESS, { profile: resp.data });
          resolve(resp.data);
        })
        .catch((error) => {
          commit(types.FETCH_PROFILE_FAILURE, { msg: error });
          reject(error);
        });
    });
  },

  fetchAccounts({ commit }) {
    return new Promise((resolve, reject) => {
      commit(types.FETCH_ACCOUNTS_REQUEST);

      AccountApi.fetchAccounts()
        .then((resp) => {
          commit(types.FETCH_ACCOUNTS_SUCCESS, { accounts: resp.data });
          resolve(resp.data);
        })
        .catch((error) => {
          commit(types.FETCH_ACCOUNTS_FAILURE, { msg: error });
          reject(error);
        });
    });
  },

  changeSelectedAccount({ commit }, { account }) {
    commit(types.CHANGE_SELECTED_ACCOUNT, { account: account });
  },

  changeAccountId({ commit }, { id }) {
    commit(types.CHANGE_SELECTED_ACCOUNT_ID, { id: id });
  },

  updateActiveFilters({ commit }, { filters }) {
    return new Promise((resolve, _reject) => {
      commit(types.UPDATE_ACTIVE_FILTERS, { filters: filters });
      resolve();
    });
  },
};
