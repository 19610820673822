export default {
  mailInviteLink({ name, teamName, link }) {
    let subject = `${name} Invited You to Join ${teamName} on 6Dos.co!`;
    let body =
      `Hey [Person's Name],\n\n` +
      `I just joined Six Degrees of Separation (6DOS) and I'd like to invite you to join my team so we can share our networks.\n` +
      `It's a brand new networking tool that specializes in helping you to find the best person to introduce you to that person you've always wanted to meet. Please join my team so we can find brand new ways to grow and help each other!\n\n` +
      `Click below to join my team:\n` +
      `${link}\n\n` +
      `6DOS is a tool that focuses on quality relationships between people and uses machine learning to calculate a Relationship Score for everyone. This allows you to search your team's connections to find the user who has the highest Relationship Score with the person you'd like to meet, empowering users to focus their attention solely on those who have strong relationships to ask for successful introductions. If you want more information, you can visit 6dos.co to see it in action!\n\n` +
      `Thank you,\n\n` +
      `${name}`;

    let str = `mailto:?subject=${subject}&body=${body}`;

    return encodeURI(str).replace(/,/gi, "%2C");
  },
};
