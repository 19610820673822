import axios from "axios";

import Utils from "@/api/utils";

export default {
  deleteContact(contactId) {
    let url = Utils.contactApiUrl(`/v1/contacts/${contactId}`);

    return axios
      .delete(url, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },

  fetchContacts(opts) {
    let params = {
      limit: opts.limit || 30,
      offset: opts.offset || 0,
      q: opts.q,
    };

    let url = Utils.contactApiUrl(`/v1/contacts`);

    return axios
      .get(url, { params: params, headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },

  mergeContacts(contactIds) {
    let body = {
      contact_ids: contactIds,
    };

    let url = Utils.contactApiUrl(`/v1/contacts/merge`);

    return axios
      .post(url, body, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },

  searchContacts(opts) {
    let params = {
      limit: opts.limit || 30,
      offset: opts.offset || 0,
      filters: opts.filters,
    };

    let url = Utils.contactApiUrl(`/v1/contacts/search`);

    return axios
      .post(url, params, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },
};
