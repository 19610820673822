<template>
  <v-list
    three-line
    subheader
    dense
    class="white"
  >
    <v-subheader class="card-title grey lighten-3">
      <span class="caption">Work Experience</span>
    </v-subheader>
    <template v-for="(item, index) in value">
      <v-divider
        v-if="index > 0"
        :key="index"
      />
      <v-list-tile
        :key="item.value"
        class="work-experience py-2"
        dark
      >
        <v-list-tile-content>
          <v-list-tile-title v-if="item.value">
            <strong v-text="item.value" />
          </v-list-tile-title>
          <v-list-tile-sub-title v-if="item.detail">
            <strong v-text="item.detail" />
          </v-list-tile-sub-title>
          <v-list-tile-sub-title v-if="item.start_date || item.end_date">
            <span class="caption grey--text">{{
              formatRange(item.start_date, item.end_date)
            }}</span>
          </v-list-tile-sub-title>
        </v-list-tile-content>
        <v-list-tile-action></v-list-tile-action>
      </v-list-tile>
    </template>
  </v-list>
</template>

<script>
import { formatDate } from "../../fuzzyDateFormatter";

export default {
  name: "WorkExperienceList",

  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  methods: {
    formatRange(start, end) {
      if (!start) {
        return formatDate(end);
      }
      if (!end) {
        return formatDate(start);
      }

      return `${formatDate(start)} - ${formatDate(end)}`;
    },
  },
};
</script>

<style lang="scss">
.work-experience {
  .v-list__tile {
    height: auto !important;
  }
}
</style>
