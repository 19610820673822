<template>
  <v-dialog
    v-model="open"
    width="290"
    style="overflow: auto; -webkit-overflow-scrolling: touch"
    @keydown.esc="cancelDelete"
  >
    <v-card class="pa-2">
      <v-card-title
        class="card-title"
        v-text="title"
      />
      <v-card-text v-text="text" />
      <v-card-actions>
        <v-spacer />
        <v-btn
          ref="deleteButton"
          color="rubber"
          class="elevation-0"
          dark
          @click.native="confirmDelete"
        >
          {{ deleteTitle }}
        </v-btn>
        <v-btn
          ref="cancelButton"
          color="primary"
          outline
          @click.native="cancelDelete"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-progress-linear
      v-if="loading"
      class="ma-0"
      indeterminate
    />
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmDeleteDialog",
  props: {
    deleteTitle: {
      type: String,
      default: "Delete",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "Are you sure you want to delete?",
    },
    title: {
      type: String,
      default: "Delete",
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      open: this.value,
    };
  },
  watch: {
    value: {
      handler() {
        this.open = this.value;
      },
    },
    open: {
      handler() {
        if (this.open) {
          return;
        }
        this.cancelDelete();
      },
    },
  },
  methods: {
    cancelDelete() {
      this.$emit("cancel-delete");
    },
    confirmDelete() {
      this.$emit("confirm-delete");
    },
  },
};
</script>
