<template>
  <v-content fill-height>
    <img
      src="/meeting-bg.jpeg"
      class="login-bg"
    />
    <v-layout
      row
      fill-height
      align-center
      justify-center
      wrap
    >
      <v-flex
        xs12
        sm6
        md4
        lg3
        xl3
      >
        <v-card
          class="elevation-15"
          color="#222"
          tile
        >
          <img
            width="100%"
            class="pa-5"
            src="@/assets/logo.png"
          />
        </v-card>
        <v-card
          color="#fcfcfa"
          class="pa-4 elevation-15"
          tile
        >
          <v-card-text>
            <v-form @submit.prevent="requestResetPassword">
              <v-text-field
                v-model="newPassword"
                label="Password"
                type="password"
                placeholder="New Password"
                required
                solo
              />
              <v-text-field
                v-model="retypePassword"
                label="Password"
                type="password"
                placeholder="Re-enter Password"
                required
                solo
              />
              <v-btn
                type="submit"
                color="primary"
                large
                dark
                block
              >
                Reset Password
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-content>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ResetPassword",
  data: function () {
    return {
      newPassword: "",
      retypePassword: "",
    };
  },
  methods: {
    ...mapActions(["changePassword"]),
    requestResetPassword() {
      const url = window.location.href;
      const token = url.substring(url.lastIndexOf("=") + 1);

      if (this.newPassword.length === 0 || this.retypePassword === 0) {
        this.$notify({
          group: "notifs",
          text: "Please enter your password.",
          title: "Password Reset Failed",
          type: "error",
        });
      } else if (this.newPassword === this.retypePassword) {
        this.changePassword({ token: token, password: this.newPassword })
          .then(() => {
            this.$notify({
              group: "notifs",
              text: "Password has been reset. Please login to your account with your new password.",
              title: "Password Reset Success",
              type: "success",
            });
            this.$router.push("/login");
          })
          .catch(() => {
            this.$notify({
              group: "notifs",
              text: "Password could not be reset at this time.",
              title: "Password Reset Failed",
              type: "error",
            });
          });
      } else {
        this.$notify({
          group: "notifs",
          text: "Please make sure your passwords match.",
          title: "Password Reset Failed",
          type: "error",
        });
      }
    },
  },
};
</script>

<style lang="scss">
.login-bg {
  bottom: 0;
  left: 0;
  height: 100%;
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 0;
}
</style>
