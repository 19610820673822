import PaymentApi from "@/api/payment";
import Vue from "vue";

export const FETCH_INVOICES_REQUEST = "FETCH_INVOICES_REQUEST";
export const FETCH_INVOICES_SUCCESS = "FETCH_INVOICES_SUCCESS";
export const FETCH_INVOICES_FAILURE = "FETCH_INVOICES_FAILURE";

export const FETCH_PAYMENT_METHOD_REQUEST = "FETCH_PAYMENT_METHOD_REQUEST";
export const FETCH_PAYMENT_METHOD_SUCCESS = "FETCH_PAYMENT_METHOD_SUCCESS";
export const FETCH_PAYMENT_METHOD_FAILURE = "FETCH_PAYMENT_METHOD_FAILURE";

export const FETCH_PAYMENT_PROFILE_REQUEST = "FETCH_PAYMENT_PROFILE_REQUEST";
export const FETCH_PAYMENT_PROFILE_SUCCESS = "FETCH_PAYMENT_PROFILE_SUCCESS";
export const FETCH_PAYMENT_PROFILE_FAILURE = "FETCH_PAYMENT_PROFILE_FAILURE";

export const FETCH_PAYMENTS_REQUEST = "FETCH_PAYMENTS_REQUEST";
export const FETCH_PAYMENTS_SUCCESS = "FETCH_PAYMENTS_SUCCESS";
export const FETCH_PAYMENTS_FAILURE = "FETCH_PAYMENTS_FAILURE";

export const RESET_STATE = "RESET_STATE";

const state = {
  loading: false,
  error: null,
  invoices: [],
  payments: [],
  paymentMethod: null,
  paymentProfile: null,
};

const getters = {
  getInvoices: (state) => {
    return state.invoices;
  },

  getPaymentMethod: (state) => {
    return state.paymentMethod;
  },

  getPaymentProfile: (state) => {
    return state.paymentProfile;
  },

  getPayments: (state) => {
    return state.payments;
  },

  isLoading: (state) => {
    return state.loading;
  },
};

const actions = {
  fetchInvoices({ commit }) {
    return new Promise((resolve, reject) => {
      commit(FETCH_INVOICES_REQUEST);
      PaymentApi.fetchInvoices()
        .then((resp) => {
          commit(FETCH_INVOICES_SUCCESS, { invoices: resp.data });
          resolve(resp.data);
        })
        .catch((error) => {
          commit(FETCH_INVOICES_FAILURE, { msg: error });
          reject(error);
        });
    });
  },

  fetchPaymentMethod({ commit }) {
    return new Promise((resolve, reject) => {
      commit(FETCH_PAYMENT_METHOD_REQUEST);
      PaymentApi.fetchPaymentMethod()
        .then((resp) => {
          commit(FETCH_PAYMENT_METHOD_SUCCESS, { paymentMethod: resp.data });
          resolve(resp.data);
        })
        .catch((error) => {
          commit(FETCH_PAYMENT_METHOD_FAILURE, { msg: error });
          reject(error);
        });
    });
  },

  fetchPaymentProfile({ commit }) {
    return new Promise((resolve, reject) => {
      commit(FETCH_PAYMENT_PROFILE_REQUEST);
      PaymentApi.fetchPaymentProfile()
        .then((resp) => {
          commit(FETCH_PAYMENT_PROFILE_SUCCESS, { paymentProfile: resp.data });
          resolve(resp.data);
        })
        .catch((error) => {
          commit(FETCH_PAYMENT_PROFILE_FAILURE, { msg: error });
          reject(error);
        });
    });
  },

  fetchPayments({ commit }) {
    return new Promise((resolve, reject) => {
      commit(FETCH_PAYMENTS_REQUEST);
      PaymentApi.fetchPayments()
        .then((resp) => {
          commit(FETCH_PAYMENTS_SUCCESS, { payments: resp.data });
          resolve(resp.data);
        })
        .catch((error) => {
          commit(FETCH_PAYMENTS_FAILURE, { msg: error });
          reject(error);
        });
    });
  },
};

const mutations = {
  [FETCH_INVOICES_REQUEST](state) {
    state.loading = true;
    state.error = null;
  },

  [FETCH_INVOICES_SUCCESS](state, { invoices }) {
    state.loading = false;
    state.error = null;

    state.invoices = !invoices ? [] : invoices;
  },

  [FETCH_INVOICES_FAILURE](state, { error }) {
    state.loading = false;
    state.error = error;
  },

  [FETCH_PAYMENT_METHOD_REQUEST](state) {
    state.loading = true;
    state.error = null;
  },

  [FETCH_PAYMENT_METHOD_SUCCESS](state, { paymentMethod }) {
    state.loading = false;
    state.error = null;

    Vue.set(state, "paymentMethod", paymentMethod);
  },

  [FETCH_PAYMENT_METHOD_FAILURE](state, { error }) {
    state.loading = false;
    state.error = error;
  },

  [FETCH_PAYMENT_PROFILE_REQUEST](state) {
    state.loading = true;
    state.error = null;
  },

  [FETCH_PAYMENT_PROFILE_SUCCESS](state, { paymentProfile }) {
    state.loading = false;
    state.error = null;

    Vue.set(state, "paymentProfile", paymentProfile);
  },

  [FETCH_PAYMENT_PROFILE_FAILURE](state, { error }) {
    state.loading = false;
    state.error = error;
  },

  [FETCH_PAYMENTS_REQUEST](state) {
    state.loading = true;
    state.error = null;
  },

  [FETCH_PAYMENTS_SUCCESS](state, { payments }) {
    state.loading = false;
    state.error = null;

    state.payments = !payments ? [] : payments;
  },

  [FETCH_PAYMENTS_FAILURE](state, { error }) {
    state.loading = false;
    state.error = error;
  },

  [RESET_STATE](state) {
    const initialState = {
      loading: false,
      error: null,
      invoices: [],
      payments: [],
      paymentMethod: null,
      paymentProfile: null,
    };
    Object.assign(state, initialState);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
