import Vue from "vue";
import whitelabelConfig from "@/whitelabel.config";

export default {
  authHeaders() {
    return { Authorization: `Bearer ${Vue.cookie.get("token")}` };
  },
  accountApiUrl(path) {
    return this.getWhiteLabelConfig().accountApiUrl + path;
  },
  contactApiUrl(path) {
    return this.getWhiteLabelConfig().contactApiUrl + path;
  },
  contactImportApiUrl(path) {
    return this.getWhiteLabelConfig().contactImportApiUrl + path;
  },
  getWhiteLabelConfig() {
    return whitelabelConfig[process.env.VUE_APP_BRAND];
  },
  paymentApiUrl(path) {
    return this.getWhiteLabelConfig().paymentApiUrl + path;
  },
};
