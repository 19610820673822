<template>
  <v-card
    class="elevation-0"
    color="transparent"
  >
    <v-card-text>
      <h1 class="text-xs-center">Welcome to 6DOS.</h1>
      <p class="text-xs-center">Watch the video to get started.</p>
      <div class="video-container">
        <iframe
          width="100%"
          height="100%"
          :src="videoUrl"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="primary"
        @click="next"
      >
        Continue
      </v-btn>
      <v-spacer />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "WatchVideo",
  data() {
    return {
      videoUrl:
        "https://www.youtube.com/embed/SoHYWnpAyhA?feature=oembed&start&end&wmode=opaque&loop=0&controls=1&mute=0&rel=0&modestbranding=1",
    };
  },
  methods: {
    next() {
      this.stopVideo();
      this.$emit("continue");
    },
    stopVideo() {
      this.videoUrl = "";
    },
  },
};
</script>

<style>
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
