import axios from "axios";
import Utils from "@/api/utils";

export default {
  doCancelSubscription() {
    let url = Utils.paymentApiUrl(`/v1/subscriptions/cancel`);
    return axios
      .put(url, {}, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },

  doReactivateSubscription() {
    let url = Utils.paymentApiUrl(`/v1/subscriptions/reactivate`);
    return axios
      .put(url, {}, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },

  fetchCheckoutSessionId() {
    let url = Utils.paymentApiUrl(`/v1/checkout`);
    return axios
      .get(url, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },

  fetchInvoices() {
    let url = Utils.paymentApiUrl(`/v1/invoices`);
    return axios
      .get(url, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },

  fetchPaymentMethod() {
    let url = Utils.paymentApiUrl(`/v1/payment-method`);
    return axios
      .get(url, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },

  fetchPayments() {
    let url = Utils.paymentApiUrl(`/v1/payments`);
    return axios
      .get(url, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },

  fetchPaymentProfile() {
    let url = Utils.paymentApiUrl(`/v1/profile`);
    return axios
      .get(url, { headers: Utils.authHeaders() })
      .then((resp) => resp.data)
      .catch((error) => error);
  },
};
