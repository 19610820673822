<template>
  <v-footer
    class="navy elevation-0"
    height="auto"
    app
    dark
    absolute
  >
    <v-layout
      class="pt-2"
      justify-center
      row
      wrap
    >
      <v-btn
        href="https://6dos.co/user-agreement/"
        small
        flat
      >
        Terms of Service
      </v-btn>
      <v-btn
        href="https://6dos.co/privacy-policy/"
        small
        flat
      >
        Privacy Policy
      </v-btn>
      <v-btn
        v-if="isAuthenticated"
        :to="{ path: '/changelog' }"
        small
        flat
      >
        Changelog
      </v-btn>
      <v-flex
        pb-3
        text-xs-center
        xs12
      >
        <div class="version">&copy;{{ footerText }}</div>
      </v-flex>
    </v-layout>
  </v-footer>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { version } from "../../package.json";

export default {
  name: "MobileAppFooter",
  computed: {
    ...mapGetters(["isAuthenticated", "getUsername"]),
    version() {
      return version;
    },
    footerText() {
      return ` ${new Date().getFullYear()} ${
        this.$whiteLabelConfig.copyright
      } :: v${this.version}`;
    },
  },
  methods: {
    ...mapActions(["logout"]),
    requestLogout() {
      this.logout().then(() => {
        this.$router.push("/auth");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.version {
  font-size: 11px;
}
</style>
