<template>
  <v-card>
    <v-card-title class="card-title">
      <v-icon class="mr-3">insert_chart</v-icon>
      Imported Contacts
      <v-spacer />
    </v-card-title>
    <v-divider />
    <v-list
      two-line
      class="py-0"
    >
      <template v-for="(source, index) in sources">
        <v-list-tile :key="index">
          <v-list-tile-avatar>
            <font-awesome-icon
              :icon="['fab', source.icon]"
              size="2x"
              class="fa-fw grey--text text--darken-2"
            />
          </v-list-tile-avatar>
          <v-list-tile-content>
            {{ source.name }}
            <v-list-tile-sub-title>Import Source</v-list-tile-sub-title>
          </v-list-tile-content>
          <v-list-tile-content>
            {{ getSourceTotal(source.name) }}
            <v-list-tile-sub-title>Total Contacts</v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
      </template>
      <v-btn
        large
        block
        class="primary my-0"
        @click="$router.push('/import')"
      >
        IMPORT CONTACTS
      </v-btn>
    </v-list>
  </v-card>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "ContactSourceCard",
  components: {
    "font-awesome-icon": FontAwesomeIcon,
  },
  props: {
    sourceTotalInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sources: [
        { name: "Exchange", icon: "microsoft" },
        { name: "Google", icon: "google" },
        { name: "LinkedIn", icon: "linkedin" },
        { name: "Office 365", icon: "windows" },
        { name: "iCloud", icon: "apple" },
      ],
    };
  },
  computed: {
    totalContacts() {
      if (this.sourceTotalInfo == undefined) {
        return 0;
      }
      if (this.sourceTotalInfo.all == undefined) {
        return 0;
      }
      return this.sourceTotalInfo.all;
    },
  },
  methods: {
    getSourceTotal(sourceName) {
      if (this.sourceTotalInfo == undefined) {
        return 0;
      }
      if (this.sourceTotalInfo.all == undefined) {
        return 0;
      }
      switch (sourceName) {
        case "Google":
          return this.$n(this.sourceTotalInfo.google);
        case "LinkedIn":
          return this.$n(this.sourceTotalInfo.linkedin);
        case "Office 365":
          return this.$n(this.sourceTotalInfo.microsoft);
        case "iCloud":
          return this.$n(this.sourceTotalInfo.icloud);
        case "Exchange":
          return this.$n(this.sourceTotalInfo.exchange);
        default:
          return 0;
      }
    },
  },
};
</script>
