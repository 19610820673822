<template>
  <v-card>
    <v-card-title class="card-title">
      <v-icon class="mr-3">account_circle</v-icon>
      {{ profile.name || "No User Name" }}
      <v-spacer />
    </v-card-title>
    <v-divider />
    <v-list
      two-line
      dense
    >
      <v-list-tile>
        <v-list-tile-avatar>
          <v-icon>mail</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          {{ profile.email || "No E-mail" }}
          <v-list-tile-sub-title>E-mail</v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>
    </v-list>
  </v-card>
</template>

<script>
export default {
  props: {
    profile: {
      type: Object,
      required: true,
    },
  },
};
</script>
