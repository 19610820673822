<template>
  <v-content fill-height>
    <v-layout
      row
      fill-height
      wrap
    >
      <v-flex
        xs12
        lg6
      >
        <v-layout
          row
          wrap
          align-center
          justify-center
          fill-height
        >
          <v-flex
            class="text-xs-left"
            pa-3
            xs12
            lg8
          >
            <SignupForm
              @forgot-password="toggleForgotPassword = true"
              @sign-up-email="signupEmail($event)"
              @sign-up-google="signupGoogle"
              @sign-up-microsoft="signupMicrosoft"
            />
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex
        class="navy"
        xs12
        lg6
      >
        <v-layout
          row
          wrap
          align-center
          justify-center
          fill-height
        >
          <v-flex shrink>
            <img
              width="100%"
              src="@/assets/logo.png"
            />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-content>
</template>

<script>
import { mapActions } from "vuex";
import OAuth from "@/oauth";
import SignupForm from "@/components/login/SignupForm";

export default {
  name: "Signup",
  components: {
    SignupForm,
  },
  computed: {
    redirectUri() {
      return `${this.$whiteLabelConfig.webUrl}/signup`;
    },
  },
  methods: {
    ...mapActions(["oauth", "register"]),
    doSuccessfulRedirect() {
      let redirectTo = this.$route.query.from;
      if (redirectTo == undefined) {
        this.$router.push("/connections");
      } else {
        this.$router.push(redirectTo);
      }
    },
    notifyError(error) {
      this.$notify({
        group: "notifs",
        title: "Registration Failed",
        text: error,
        type: "error",
      });
    },
    signupEmail({ email, name, password }) {
      this.register({ email: email, name: name, password: password })
        .then(() => {
          this.doSuccessfulRedirect();
        })
        .catch((error) => {
          this.notifyError(error);
        });
    },
    signupGoogle() {
      let popup = OAuth.googlePopup("Register an Account", this.redirectUri);

      popup
        .open(this.redirectUri, false)
        .then((resp) => {
          let params = {
            code: resp.code,
            provider: "google",
            redirectUri: this.redirectUri,
            type: "register",
          };

          this.oauth(params)
            .then((_) => {
              this.doSuccessfulRedirect();
            })
            .catch((error) => {
              this.notifyError(error);
            });
        })
        .catch((error) => {
          if (error.message === "CLOSED") {
            return;
          }
          this.notifyError();
        });
    },
    signupMicrosoft() {
      let popup = OAuth.microsoftPopup("Register an Account", this.redirectUri);

      popup
        .open(this.redirectUri, false)
        .then((resp) => {
          let params = {
            code: resp.code,
            provider: "microsoft",
            redirectUri: this.redirectUri,
            type: "register",
          };

          this.oauth(params)
            .then((_) => {
              this.doSuccessfulRedirect();
            })
            .catch((error) => {
              this.notifyError(error);
            });
        })
        .catch((error) => {
          if (error.message === "CLOSED") {
            return;
          }
          this.notifyError();
        });
    },
  },
};
</script>
