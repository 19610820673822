<template>
  <v-container
    grid-list-lg
    fluid
  >
    <AppHeader />
    <v-content fill-height>
      <v-layout row>
        <v-flex>
          <PageTitle value="Changelog" />
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex>
          <ChangelogContent />
        </v-flex>
      </v-layout>
    </v-content>
  </v-container>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import ChangelogContent from "@/components/global/ChangelogContent.vue";
import PageTitle from "@/components/global/PageTitle";

export default {
  name: "Changelog",
  components: {
    AppHeader,
    ChangelogContent,
    PageTitle,
  },
};
</script>
