<template>
  <v-container
    grid-list-lg
    fluid
  >
    <AppHeader />
    <v-content fill-height>
      <v-layout
        row
        justify-center
      >
        <v-flex
          xs12
          md8
        >
          <v-layout
            row
            wrap
          >
            <v-flex>
              <PageTitle value="Build Your Score" />
            </v-flex>
          </v-layout>
          <v-layout
            row
            wrap
          >
            <v-flex xs12>
              <v-list
                class="pa-0 elevation-3"
                two-line
              >
                <v-list-group
                  v-for="(item, i) in source"
                  :key="i"
                >
                  <template v-slot:activator>
                    <v-list-tile>
                      <v-list-tile-avatar
                        size="30"
                        tile
                      >
                        <img :src="iconImg(item.sourceName)" />
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title class="card-title">
                          {{ item.name }}
                        </v-list-tile-title>
                      </v-list-tile-content>
                      <v-list-tile-action
                        v-if="getSourceTotal(item.sourceName)"
                      >
                        <v-chip>
                          <v-avatar class="grey">
                            <v-icon
                              class="white--text"
                              small
                            >
                              account_box
                            </v-icon>
                          </v-avatar>
                          <span>{{ $n(getSourceTotal(item.sourceName)) }}</span>
                        </v-chip>
                      </v-list-tile-action>
                    </v-list-tile>
                  </template>
                  <ImportCard :type="sourceName(item.sourceName)" />
                </v-list-group>
              </v-list>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <ImportTourDialog
        v-model="displayTourDialog"
        @begin-tour="startTour($event)"
        @dismiss="displayTourDialog = false"
      />
    </v-content>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import API from "@/api/import";
import AppHeader from "@/components/AppHeader";
import ImportCard from "@/components/import/ImportCard";
import ImportTourDialog from "@/components/import/ImportTourDialog";
import PageTitle from "@/components/global/PageTitle";

const tourUrls = {
  "Apple/vCard": "/import?product_tour_id=73237",
  Exchange: "/import?product_tour_id=73240",
  Google: "/import?product_tour_id=72952",
  LinkedIn: "/import?product_tour_id=73241",
  "Office 365": "/import?product_tour_id=73236",
};

export default {
  name: "Import",
  components: {
    AppHeader,
    ImportCard,
    ImportTourDialog,
    PageTitle,
  },
  data() {
    return {
      connections: [],
      displayTourDialog: false,
      file: "",
      source: [
        { sourceName: "apple/vcard", name: "apple/vcard" },
        { sourceName: "exchange", name: "outlook" },
        { sourceName: "google", name: "google" },
        { sourceName: "linkedin", name: "linkedin" },
        { sourceName: "office365", name: "office365" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      profile: "getProfile",
      userStats: "statistics/getUserStats",
      needsTourDialog: "statistics/needsTourDialog",
    }),
    sourceTotalInfo() {
      let sourceTotalInfo = this.userStats.total_imported || {};
      return sourceTotalInfo;
    },
  },
  watch: {
    needsTourDialog() {
      this.displayTourDialog = this.needsTourDialog;
    },
    profile() {
      if (this.profile.id) {
        this.fetchUserStats({ userId: this.profile.id });
      }
    },
  },
  mounted() {
    this.displayTourDialog = this.needsTourDialog;
  },
  methods: {
    ...mapActions({
      beginTour: "statistics/beginTour",
      fetchUserStats: "statistics/fetchUserStats",
      importContacts: "importContacts",
    }),
    startTour(source) {
      this.beginTour();
      this.$router.push(tourUrls[source]);
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    sourceName(name) {
      switch (name) {
        case "google":
          return "google";
        case "apple/vcard":
          return "vcard";
        case "office365":
          return "microsoft";
        case "linkedin":
          return "linkedin";
        case "exchange":
          return "exchange";
        default:
          "";
      }
    },
    submitDataImport() {
      API.submitDataImport(this.file, this.importType);
    },
    iconImg(source) {
      switch (source) {
        case "apple/vcard":
          return require("@/assets/social/apple.png");
        case "google":
          return require("@/assets/social/google.png");
        case "linkedin":
          return require("@/assets/social/linkedin.png");
        case "office365":
          return require("@/assets/social/office365.png");
        case "exchange":
          return require("@/assets/outlook-logo.png");
        default:
          return "";
      }
    },
    getSourceTotal(sourceName) {
      let sourceTotalInfo = this.sourceTotalInfo;
      if (sourceTotalInfo == {}) {
        return 0;
      }
      if (sourceTotalInfo.all == undefined) {
        return 0;
      }
      switch (sourceName) {
        case "google":
          return sourceTotalInfo.google;
        case "linkedin":
          return sourceTotalInfo.linkedin;
        case "office365":
          return sourceTotalInfo.microsoft;
        case "apple/vcard":
          return sourceTotalInfo.icloud;
        case "exchange":
          return sourceTotalInfo.exchange;
        default:
          return 0;
      }
    },
  },
};
</script>
