import axios from "axios";
import Utils from "@/api/utils";

export default {
  fetchUserStats(userId) {
    let url = Utils.contactApiUrl(`/v1/users/${userId}/statistics`);
    return axios
      .get(url, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },
  fetchTeamStats(teamId) {
    let url = Utils.contactApiUrl(`/v1/accounts/${teamId}/statistics`);
    return axios
      .get(url, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },
};
