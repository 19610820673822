<script>
import { Pie } from "vue-chartjs";

export default {
  extends: Pie,
  props: {
    sourceTotalInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chartData: {
        labels: ["Google", "Office 365", "LinkedIn", "iCloud", "Exchange"],
        datasets: [
          {
            label: "Imported Contacts",
            backgroundColor: [
              "#3cba54",
              "#D53A00",
              "#0077B5",
              "#7d7d7d",
              "#00a1f1",
            ],
            data: [],
          },
        ],
      },
    };
  },
  mounted() {
    this.setChartData();
    this.displayChart();
  },
  methods: {
    displayChart() {
      this.renderChart(this.chartData);
    },
    setChartData() {
      this.chartData.datasets[0].data = [
        this.sourceTotalInfo.google,
        this.sourceTotalInfo.microsoft,
        this.sourceTotalInfo.linkedin,
        this.sourceTotalInfo.icloud,
        this.sourceTotalInfo.exchange,
      ];
    },
  },
};
</script>
