<template>
  <v-card
    class="transparent"
    flat
  >
    <v-card-title class="pb-0">
      <h2
        v-if="hasPaymentMethod"
        class="display-1 font-weight-light"
      >
        Payment Method on File
      </h2>
      <h2
        v-else
        class="display-1 font-weight-light grey--text"
      >
        No Payment Method on File
      </h2>
      <br />
    </v-card-title>
    <v-card-text>
      <v-chip
        class="chip-style ml-0"
        dark
        small
      >
        <span> Powered by <strong>stripe</strong> </span>
      </v-chip>
      <br />
      <span>Payments are securely processed each month with Stripe.</span>
    </v-card-text>
    <v-card-text v-if="hasPaymentMethod">
      <v-list
        two-line
        class="py-0 elevation-3"
      >
        <v-list-tile>
          <v-list-tile-avatar>
            <v-icon large>payment</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            {{ cardInfoText }}
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-card-text>
    <!-- <v-card-actions>
      <v-btn
        v-if="hasPaymentMethod"
        color="primary ml-2 mt-3"
        large
        @click="updateCreditCard"
      >
        Update Credit Card
      </v-btn>
      <v-btn
        v-else
        color="primary ml-2"
        large
        @click="addCreditCard"
      >
        Add Credit Card
      </v-btn>
    </v-card-actions> -->
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PaymentMethod",
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      paymentMethod: "payment/getPaymentMethod",
      paymentProfile: "payment/getPaymentProfile",
    }),
    cardInfoText() {
      if (!this.paymentMethod) {
        return "No Card Information";
      }
      return this.paymentMethod;
    },
    hasPaymentMethod() {
      return this.paymentMethod ? true : false;
    },
  },
  methods: {
    addCreditCard() {
      // TODO
    },
    updateCreditCard() {
      // TODO
    },
  },
};
</script>

<style scoped>
.transparent {
  background-color: transparent !important;
}

.chip-style {
  background-color: grey;
  border-radius: 6px;
}
</style>
