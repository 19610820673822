<template>
  <v-navigation-drawer
    v-model="open"
    :width="drawerWidth"
    class="detail-drawer navy"
    fixed
    temporary
    right
  >
    <v-card-title class="navy lighten-1 white--text pa-1">
      <v-layout
        row
        justify-end
      >
        <v-flex shrink>
          <v-btn
            dark
            icon
            @click="close()"
          >
            <v-icon> close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card-title>
    <v-card
      height="100%"
      class="navy pa-1"
    >
      <v-card-title class="navy white--text">
        <v-layout row>
          <v-flex
            class="wrap-text pa-2"
            xs12
          >
            <Avatar
              :src="photo"
              :username="name"
              :size="80"
              class="header-avatar"
            />
            <div class="header-container">
              <div class="name-and-icon">
                <span class="detail-headertext white--text">{{ name }}</span>
                <v-tooltip
                  v-if="additionalNamesExist"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="info-icon white--text ml-1"
                      v-bind="attrs"
                      v-on="on"
                    >
                      info
                    </v-icon>
                  </template>
                  <span>{{ additionalNames }}</span>
                </v-tooltip>
              </div>
              <div v-if="jobTitle != null && jobTitle !== ''">
                <span class="detail-subtext white--text">{{ jobTitle }}</span>
              </div>
              <div v-if="company && company != ''">
                <v-icon
                  class="mr-1"
                  small
                  dark
                >
                  domain
                </v-icon>
                <span class="detail-subtext white--text">{{ company }}</span>
              </div>
              <div class="source-container pt-2">
                <font-awesome-icon
                  v-for="(source, index) in allSources"
                  :key="index"
                  :icon="['fab', source]"
                  :opacity="opacity(source)"
                  size="lg"
                  class="fa-fw mr-1"
                />
              </div>
            </div>
          </v-flex>
        </v-layout>
      </v-card-title>
      <v-card-actions
        v-if="knowsConnection"
        class="navy"
      >
        <v-btn
          v-if="false"
          class="white--text"
          color="blue"
          large
          block
        >
          View Profile
        </v-btn>
        <v-btn
          class="white--text"
          color="primary"
          large
          block
          @click="confirmDeleteDialog = true"
        >
          Delete
        </v-btn>
      </v-card-actions>
      <v-layout
        row
        class="navy ma-0 pa-0"
      >
        <v-flex>
          <WorkExperienceList
            v-if="hasWorkExperience"
            v-model="workExperience"
            class="mb-3"
          />
          <ContactInfoList
            v-if="hasInfo"
            v-model="info"
            class="mb-3"
          />
          <ConnectorList
            v-model="scores"
            :avatar-height="avatarHeight"
            :connection-name="name"
            :knows-connection="knowsConnection"
          />
        </v-flex>
      </v-layout>
    </v-card>
    <ConfirmDeleteDialog
      v-model="confirmDeleteDialog"
      title="Confirm Delete"
      text="Are you sure you want to delete this connection?"
      @confirm-delete="requestDelete"
      @cancel-delete="confirmDeleteDialog = false"
    />
  </v-navigation-drawer>
</template>

<script>
import {library} from "@fortawesome/fontawesome-svg-core";
import {
  faGoogle,
  faApple,
  faMicrosoft,
  faLinkedin,
  faWindows,
} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import Avatar from "vue-avatar";
import ConfirmDeleteDialog from "@/components/global/ConfirmDeleteDialog";
import ConnectorList from "./ConnectorList";
import ContactApi from "@/api/contacts";
import ContactInfoList from "./ContactInfoList";
import WorkExperienceList from "./WorkExperienceList";

library.add(faGoogle, faApple, faMicrosoft, faLinkedin, faWindows);

const faIcons = {
  google: "google",
  microsoft: "windows",
  linkedin: "linkedin",
  icloud: "apple",
  exchange: "microsoft",
};

export default {
  name: "ConnectionDetailDialog",

  components: {
    Avatar,
    ConfirmDeleteDialog,
    ConnectorList,
    ContactInfoList,
    WorkExperienceList,
    "font-awesome-icon": FontAwesomeIcon,
  },

  props: {
    connection: {
      type: Object,
      required: true,
    },

    value: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      confirmDeleteDialog: false,
      open: this.value,
      allSources: ["google", "apple", "linkedin", "microsoft", "windows"],
    };
  },

  computed: {
    additionalNamesExist() {
      if (!this.connection || !this.connection.info) {
        return false;
      }

      return this.connection.info.filter((x) => x.type === "name").length > 1;
    },

    additionalNames() {
      if (!this.connection || !this.connection.info) {
        return null;
      }

      return this.connection.info.filter((x) => x.type === "name").map(name => name.value).join(', ');
    },

    avatarHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 44;
        case "sm":
          return 44;
        case "md":
          return 44;
        case "lg":
          return 44;
        case "xl":
          return 44;
        default:
          return 64;
      }
    },

    company() {
      if (!this.connection.info) {
        return null;
      }

      // Check if there is at least one work experience entry
      if (this.workExperience.length > 0) {
        // Return the job title of the most recent work experience
        return this.workExperience[0].detail;
      }

      return null;
    },

    drawerWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 320;
        case "sm":
          return 450;
        case "md":
          return 450;
        case "lg":
          return 450;
        case "xl":
          return 450;
        default:
          return 450;
      }
    },

    hasInfo() {
      return this.info.length > 0;
    },

    hasWorkExperience() {
      return this.workExperience.length > 0;
    },

    info() {
      if (!this.connection.info || this.connection.info.length === 0) {
        return [];
      }
      let info = this.connection.info.filter(
          (x) =>
              !["name", "work_experience", "job_title", "photo"].includes(x.type)
      );
      return info;
    },

    jobTitle() {
      if (!this.connection.info) {
        return null;
      }

      // Check if there is at least one work experience entry
      if (this.workExperience.length > 0) {
        // Return the job title of the most recent work experience
        return this.workExperience[0].value;
      }

      return null;
    },

    knowsConnection() {
      return this.sources.length > 0;
    },

    name() {
      if (!this.connection.info) {
        return "Not set.";
      }
      let data = this.connection.info.filter((x) => x.type == "name");
      return data.length == 0 ? "Not set." : data[0].value;
    },

    photo() {
      // if (!this.connection.info) {
      //   return null;
      // }
      // let data = this.connection.info.filter((x) => x.type == "photo");
      // return data.length == 0 ? null : data[0].value;

      // Don't return photos for now.
      return null;
    },

    scores() {
      if (!this.connection.scores) {
        return [];
      }
      return this.connection.scores;
    },

    sources() {
      if (!this.connection.info) {
        return [];
      }
      let allSources = this.connection.sources;
      let uniqueSources = [...new Set(allSources)].map((x) => faIcons[x]);
      return uniqueSources;
    },

    workExperience() {
      if (!this.connection.info || this.connection.info.length === 0) {
        return [];
      }

      let workExperiences = this.connection.info.filter(item =>
          item.type === "work_experience"
      ).map(item => {
        // Transform the end_date to 'Present' if it's null and there's a start_date
        if (item.start_date && !item.end_date) {
          return { ...item, end_date: 'Present' };
        }
        return item;
      });

      // Sort by start_date, considering entries may not always have valid dates
      return workExperiences.sort((a, b) => {
        // Convert start dates to timestamps; handle missing or invalid dates
        let dateA = a.start_date ? new Date(a.start_date).getTime() : 0;
        let dateB = b.start_date ? new Date(b.start_date).getTime() : 0;

        // Sort in descending order (most recent first)
        return dateB - dateA;
      });
    },
  },

  watch: {
    value: {
      handler() {
        this.open = this.value;
      },
    },

    open: {
      handler() {
        if (!this.open) {
          this.close();
        }
      },
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },

    opacity(source) {
      return this.sources.includes(source) ? 1 : 0.5;
    },

    notifyNotImplemented() {
      this.$notify({
        group: "notifs",
        title: "Not Implemented",
        text: "This feature is not implemented yet.",
        type: "warning",
      });
    },

    requestDelete() {
      ContactApi.deleteContact(this.connection.contact_id).then((_resp) => {
        this.$emit("delete-connection", this.connection.contact_id);
      });
    },
  },
};
</script>

<style lang="scss">
.header-avatar {
  border: 2px solid white;
  margin-right: 16px;
  float: left;
}

.header-container {
  display: block;
  float: left;
}

.detail-headertext {
  color: white;
  font-size: 21px;
}

.detail-subtext {
  color: white;
  font-size: 11px;
  min-width: 10px;
}

.info-icon {
  color: inherit;
  vertical-align: middle;
  cursor: help;
}

.name-and-icon {
  display: flex;
  align-items: center;  /* Vertically center the text and icon */
  justify-content: space-between; /* Spread the name and icon apart */
}

.wrap-text {
  word-wrap: break-word;
}

.tile {
  border-radius: 0;
}

.dialog-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
}

.detail-drawer {
  z-index: 2147483001;
}
</style>
