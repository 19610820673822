<template>
  <v-dialog
    v-model="open"
    width="390"
    style="overflow: auto; -webkit-overflow-scrolling: touch"
    @keydown.esc="close"
  >
    <v-card class="pa-2">
      <v-card-title class="card-title">New Team</v-card-title>
      <v-card-text>
        <v-text-field
          v-model="name"
          :error-messages="errors['name']"
          label="Name"
          prepend-icon="domain"
          required
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          ref="saveButton"
          color="primary"
          class="elevation-0"
          dark
          @click.native="save"
        >
          Create
        </v-btn>
        <v-btn
          ref="cancelButton"
          color="primary"
          flat
          @click.native="close"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "NewTeamDialog",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      open: this.value,
      name: "",
      errors: [],
    };
  },
  watch: {
    value: {
      handler() {
        this.open = this.value;
      },
    },
    open: {
      handler() {
        if (!this.open) {
          this.close();
        }
      },
    },
  },
  methods: {
    ...mapActions("teams", ["createTeam"]),
    save() {
      this.requestCreateTeam(this.name);
    },
    requestCreateTeam(name) {
      this.createTeam({ name: name }).then((resp) => {
        this.$emit("create", resp);
        this.$emit("close");
      });
    },
    close() {
      this.name = "";
      this.$emit("close");
    },
  },
};
</script>
