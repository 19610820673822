function getProfile() {
  try {
    return JSON.parse(localStorage.getItem("profile"));
  } catch (err) {
    return {};
  }
}

function getSelectedAccount() {
  try {
    return JSON.parse(localStorage.getItem("selectedAccount"));
  } catch (err) {
    return {};
  }
}

export default {
  accounts: {},
  accountIds: [],
  activeFilters: [],
  fetchingData: false,
  email: localStorage.getItem("email") || "",
  error: null,
  profile: getProfile(),
  selectedAccount: getSelectedAccount(),
};
