<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="invoices"
      :rows-per-page-items="[10]"
    >
      <template v-slot:items="props">
        <td>{{ props.item.invoice_no }}</td>
        <td>{{ props.item.issue_date }}</td>
        <td>{{ props.item.total }}</td>
        <td>{{ props.item.amount_paid }}</td>
        <td>{{ props.item.amount_due }}</td>
        <td class="capitalize">{{ props.item.status }}</td>
        <td>{{ props.item.due_date }}</td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Invoices",
  data() {
    return {
      headers: [
        { text: "Invoice No", value: "invoiceNo", sortable: false },
        { text: "Issue Date", value: "issueDate", sortable: false },
        { text: "Total", value: "total", sortable: false },
        { text: "Amount Paid", value: "amountPaid", sortable: false },
        { text: "Amount Due", value: "amountDue", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "Due Date", value: "dueDate", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters({
      invoices: "payment/getInvoices",
    }),
  },
};
</script>

<style scoped>
.capitalize {
  text-transform: capitalize;
}
</style>
