<template>
  <v-app>
    <router-view :key="$route.path"></router-view>
    <AppFooter />
    <notifications
      group="notifs"
      width="100%"
      position="bottom center"
    ></notifications>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";

import AppFooter from "@/components/AppFooter.vue";

export default {
  name: "App",
  components: { AppFooter },
  computed: {
    ...mapGetters({
      profile: "getProfile",
    }),
  },
};
</script>

<style lang="scss">
html {
  background-color: #dcdcdc;
}

.card-title {
  text-transform: uppercase;
  letter-spacing: 7px !important;
}
</style>
