const whitelabelConfig = {
  local: {
    accountApiUrl: "http://localhost:4000",
    contactApiUrl: "http://localhost:4010",
    contactImportApiUrl: "http://localhost:4060",
    copyright: "Development Server",
    domain: "localhost",
    googleClientId:
      "21087456568-qelaj5f5grg98sci2ukfj63vej4aam8i.apps.googleusercontent.com",
    microsoftClientId: "0467a776-2947-469b-88ed-9388b18154f7",
    paymentApiUrl: "http://localhost:4090",
    stripePublishableKey: "pk_test_Np1yvKqPQGYxo9W8lae95aGQ00sIbQVdtP",
    webUrl: "http://localhost:8080",
  },
  dev: {
    accountApiUrl: "https://accounts-api.6dos.dev",
    contactApiUrl: "https://contacts-api.6dos.dev",
    contactImportApiUrl: "https://contact-importer-api.6dos.dev",
    copyright: "6DOS",
    domain: "6dos.dev",
    ganalyticsId: "G-964JK0MT4D",
    googleClientId:
      "666644998405-a58mfq53t8mjqcnt66r9ho6oufv4h5pn.apps.googleusercontent.com",
    microsoftClientId: "2daf105f-632e-44b9-b3ef-5a908ae58858",
    paymentApiUrl: "https://billing-api.6dos.dev",
    stripePublishableKey: "pk_test_Np1yvKqPQGYxo9W8lae95aGQ00sIbQVdtP",
    webUrl: "https://app.6dos.dev",
  },
  prod: {
    accountApiUrl: "https://accounts-api.6dos.co",
    contactApiUrl: "https://contacts-api.6dos.co",
    contactImportApiUrl: "https://contact-importer-api.6dos.co",
    copyright: "6DOS",
    domain: "6dos.co",
    ganalyticsId: "G-WJZ6CW2TXV",
    googleClientId:
      "666644998405-a58mfq53t8mjqcnt66r9ho6oufv4h5pn.apps.googleusercontent.com",
    microsoftClientId: "40d62509-5525-4560-8005-345ec6f9cdde",
    paymentApiUrl: "https://billing-api.6dos.co",
    stripePublishableKey: "pk_live_4RqKLIex1ZJnLrzONZ30w89500HepXPFa4",
    webUrl: "https://app.6dos.co",
  },
};

export default whitelabelConfig;
