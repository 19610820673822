import { render, staticRenderFns } from "./ScoreChip.vue?vue&type=template&id=0dcc6c60"
import script from "./ScoreChip.vue?vue&type=script&lang=js"
export * from "./ScoreChip.vue?vue&type=script&lang=js"
import style0 from "./ScoreChip.vue?vue&type=style&index=0&id=0dcc6c60&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports