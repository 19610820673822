<template>
  <v-dialog
    v-model="open"
    class="pa-0"
    width="1120"
    @keydown.esc="hideVideo()"
  >
    <v-card class="ma-0 pa-0">
      <div class="video-container">
        <iframe
          width="100%"
          height="100%"
          :src="videoUrl"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "VideoDialog",
  props: {
    videoUrl: {
      type: String,
      default: "",
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      open: this.value,
    };
  },
  watch: {
    value: {
      handler() {
        this.open = this.value;
      },
    },
    open: {
      handler() {
        if (this.open) {
          return;
        }
        this.hideVideo();
      },
    },
  },
  methods: {
    hideVideo() {
      this.$emit("hide-video");
    },
  },
};
</script>

<style>
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
