export const filters = [
  {
    key: "name",
    label: "Name",
    type: "string",
  },
  {
    key: "job_title",
    label: "Job Title",
    type: "string",
  },
  {
    key: "company",
    label: "Company",
    type: "string",
  },
  {
    key: "score",
    label: "Score",
    type: "number",
  },
  {
    key: "user_id",
    label: "User",
    type: "user",
  },
  // {
  //   'key': 'team',
  //   'label': 'Team',
  //   'type': 'team'
  // },
  // {
  //   'key': 'sources',
  //   'label': 'Sources',
  //   'type': 'source'
  // },
];
