<template>
  <v-dialog
    v-model="open"
    width="500"
    style="overflow: auto; -webkit-overflow-scrolling: touch"
    @keydown.esc="close"
  >
    <v-card class="pa-2">
      <v-card-title class="card-title">Invite Link</v-card-title>
      <v-card-text>
        <v-layout row>
          <p>
            Anyone with this link will be able to join your team and view your
            team's connections. So be careful who you share it with. The link
            will expire in 7 days for security purposes.
          </p>
        </v-layout>
        <v-layout row>
          <span
            class="link-box"
            v-text="link"
          />
          <v-btn
            v-clipboard:copy="link"
            v-clipboard:success="onCopy"
            icon
            class="pa-3"
          >
            <v-icon color="primary">file_copy</v-icon>
          </v-btn>
        </v-layout>
      </v-card-text>
      <v-card-actions v-if="isOwner">
        <v-btn
          block
          color="primary"
          :href="mailInviteLink"
          target="_blank"
        >
          Draft Invite Email
        </v-btn>
      </v-card-actions>
      <v-card-actions>
        <v-btn
          ref="cancelButton"
          color="primary"
          flat
          @click.native="close"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import API from "@/api/teams";
import Text from "@/text";

export default {
  name: "TeamInviteDialog",
  props: {
    teamId: {
      type: String,
      required: true,
    },
    teamName: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      open: this.value,
      link: "You are not authorized to invite members.",
      isOwner: false,
    };
  },
  computed: {
    ...mapGetters({
      profile: "getProfile",
    }),
    mailInviteLink() {
      return Text.mailInviteLink({
        name: this.profile.name,
        teamName: this.teamName,
        link: this.link,
      });
    },
  },
  watch: {
    value: {
      handler() {
        this.open = this.value;
      },
    },
    open: {
      handler() {
        if (this.open) {
          this.requestLink(this.teamId);
        } else {
          this.close();
        }
      },
    },
  },
  methods: {
    requestLink(teamId) {
      API.fetchInviteLink(teamId)
        .then((resp) => {
          this.link = resp.data.link;
          this.isOwner = true;
        })
        .catch((_error) => {
          this.isOwner = false;
        });
    },
    close() {
      this.link = "You are not authorized to invite members";
      this.$emit("close");
    },
    onCopy() {
      this.$notify({
        group: "notifs",
        title: "Copy Successful!",
        text: "success",
        type: "success",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.link-box {
  background-color: #eee;
  padding: 1em;
  word-break: break-all;
}
</style>
