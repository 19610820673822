<template>
  <MultilineChip
    :close="closable"
    @input="remove"
  >
    <div class="filter-chip">
      <span v-text="label" />
    </div>
  </MultilineChip>
</template>

<script>
import Filters from "@/components/filters";
import MultilineChip from "@/components/global/MultilineChip";

export default {
  name: "FilterChip",
  components: {
    MultilineChip,
  },
  props: {
    id: {
      type: Number,
      default: -1,
    },
    filter: {
      type: Object,
      required: true,
    },
    closable: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      isHovering: false,
    };
  },
  computed: {
    label() {
      if (Filters.needsValue(this.filter.where)) {
        return `${this.filter.nameLabel} ${this.filter.whereLabel} "${this.filter.valueLabel}"`;
      } else {
        return `${this.filter.nameLabel} ${this.filter.whereLabel}`;
      }
    },
    titleEllipsized() {
      return this.label;
      // if (this.label.length < 18) { return this.label }
      // return this.isHovering ? this.label : this.label.substring(0, 25) + '...'
    },
  },
  methods: {
    remove() {
      this.$emit("remove", { name: this.name, value: this.value });
    },
  },
};
</script>

<style lang="scss">
.filter-chip {
  font-size: 13px;
  // text-transform: uppercase;
}
</style>
