let months = {
  "01": "Jan",
  "02": "Feb",
  "03": "Mar",
  "04": "Apr",
  "05": "May",
  "06": "Jun",
  "07": "Jul",
  "08": "Aug",
  "09": "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

function formatDate(str) {
  var parts;

  switch (str) {
    case "PRESENT":
      return "Present";
    case (str.match(/^\d\d\d\d$/) || {}).input:
      return str;
    case (str.match(/^\d\d\d\d-\d\d$/) || {}).input:
      parts = str.split("-");
      return `${months[parts[1]]} ${parts[0]}`;
    case (str.match(/^\d\d\d\d-\d\d-\d\d$/) || {}).input:
      parts = str.split("-");
      return `${months[parts[1]]} ${parts[0]}`;
    default:
      return str;
  }
}

export { formatDate };
