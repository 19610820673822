import axios from "axios";
import Utils from "@/api/utils";

export default {
  createTeam(name) {
    let url = Utils.accountApiUrl("/v1/accounts");
    let params = { name: name };
    return axios
      .post(url, params, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },
  fetchInviteLink(teamId) {
    let url = Utils.accountApiUrl(`/v1/accounts/${teamId}/invite`);
    return axios
      .post(url, {}, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },
  fetchAllUsers(params = {}) {
    let url = Utils.accountApiUrl(`/v1/users`);
    return axios
      .get(url, { params: params, headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },
  fetchTeams(params = {}) {
    let url = Utils.accountApiUrl("/v1/accounts");
    return axios
      .get(url, { params: params, headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },
  fetchTeamStats(teamId) {
    let url = Utils.contactApiUrl(`/v1/accounts/${teamId}/statistics`);
    return axios
      .get(url, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },
  fetchTokenData(token) {
    let url = Utils.accountApiUrl(`/v1/accounts/invite/${token}`);
    return axios
      .get(url, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },
  fetchUsers(teamId) {
    let url = Utils.accountApiUrl(`/v1/accounts/${teamId}/users`);
    return axios
      .get(url, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },
  joinTeam(token) {
    let url = Utils.accountApiUrl(`/v1/accounts/invite/${token}/accept`);
    return axios
      .post(url, {}, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },

  leaveTeam(teamId, userId) {
    let url = Utils.accountApiUrl(`/v1/accounts/${teamId}/users/${userId}`);
    return axios
      .delete(url, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },
};
