<template>
  <v-chip>
    <v-avatar :class="`${color} white--text`">
      <strong>{{ Math.min(value, 100) }}</strong>
    </v-avatar>
    <span class="chip-text">
      {{ name }}
    </span>
  </v-chip>
</template>

<script>
export default {
  name: "ScoreIndicator",
  props: {
    value: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: "",
    },
  },
  computed: {
    color() {
      if (this.value > 80) {
        return "success";
      } else if (this.value > 50) {
        return "warning";
      } else {
        return "error";
      }
    },
  },
};
</script>

<style lang="scss">
.chip-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100px;
}
</style>
