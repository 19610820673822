import axios from "axios";

import Utils from "@/api/utils";

export default {
  fetchAccounts() {
    let url = Utils.accountApiUrl(`/v1/accounts`);

    return axios
      .get(url, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },
};
