import TeamApi from "@/api/teams";
import Vue from "vue";

export const CREATE_TEAM_REQUEST = "CREATE_TEAM_REQUEST";
export const CREATE_TEAM_SUCCESS = "CREATE_TEAM_SUCCESS";
export const CREATE_TEAM_FAILURE = "CREATE_TEAM_FAILURE";

export const DELETE_TEAM_REQUEST = "DELETE_TEAM_REQUEST";
export const DELETE_TEAM_SUCCESS = "DELETE_TEAM_SUCCESS";
export const DELETE_TEAM_FAILURE = "DELETE_TEAM_FAILURE";

export const FETCH_TEAMS_REQUEST = "FETCH_TEAMS_REQUEST";
export const FETCH_TEAMS_SUCCESS = "FETCH_TEAMS_SUCCESS";
export const FETCH_TEAMS_FAILURE = "FETCH_TEAMS_FAILURE";

export const FETCH_USERS_REQUEST = "FETCH_USERS_REQUEST";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILURE = "FETCH_USERS_FAILURE";

export const LEAVE_TEAM_REQUEST = "LEAVE_TEAM_REQUEST";
export const LEAVE_TEAM_SUCCESS = "LEAVE_TEAM_SUCCESS";
export const LEAVE_TEAM_FAILURE = "LEAVE_TEAM_FAILURE";

export const RESET_STATE = "RESET_STATE";

const state = {
  loading: false,
  error: null,
  teams: [],
  totalTeams: null,
  users: {},
};

const getters = {
  isLoading: (state) => {
    return state.loading;
  },

  getTeams: (state) => {
    return state.teams;
  },

  getTeam: (state) => (teamId) => {
    let team = state.teams.filter((team) => team.id === teamId)[0];
    return team;
  },

  getTeamCount: (state) => {
    return state.totalTeams;
  },

  getUsers: (state) => (teamId) => {
    return state.users[teamId];
  },
};

const actions = {
  createTeam({ commit }, { name }) {
    return new Promise((resolve, reject) => {
      commit(CREATE_TEAM_REQUEST);
      TeamApi.createTeam(name)
        .then((resp) => {
          commit(CREATE_TEAM_SUCCESS, { team: resp.data });
          resolve(resp);
        })
        .catch((error) => {
          commit(CREATE_TEAM_FAILURE, { msg: error });
          reject(error);
        });
    });
  },

  leaveTeam({ commit }, { teamId, userId }) {
    return new Promise((resolve, reject) => {
      commit(LEAVE_TEAM_REQUEST);
      TeamApi.leaveTeam(teamId, userId)
        .then((resp) => {
          commit(LEAVE_TEAM_SUCCESS, { teamId: teamId });
          resolve(resp);
        })
        .catch((error) => {
          commit(LEAVE_TEAM_FAILURE, { msg: error });
          reject(error);
        });
    });
  },

  fetchTeams({ commit }, { params }) {
    return new Promise((resolve, reject) => {
      commit(FETCH_TEAMS_REQUEST);
      TeamApi.fetchTeams(params)
        .then((resp) => {
          commit(FETCH_TEAMS_SUCCESS, { teamsInfo: resp });
          resolve(resp);
        })
        .catch((error) => {
          commit(FETCH_TEAMS_FAILURE, { msg: error });
          reject(error);
        });
    });
  },

  fetchUsers({ commit }, { teamId }) {
    return new Promise((resolve, reject) => {
      commit(FETCH_USERS_REQUEST);
      TeamApi.fetchUsers(teamId)
        .then((resp) => {
          commit(FETCH_USERS_SUCCESS, { users: resp.data, teamId: teamId });
          resolve(resp.data);
        })
        .catch((error) => {
          commit(FETCH_USERS_FAILURE, { msg: error });
          reject(error);
        });
    });
  },
};

const mutations = {
  [CREATE_TEAM_REQUEST](state) {
    state.loading = true;
    state.error = null;
  },

  [CREATE_TEAM_FAILURE](state, { error }) {
    state.loading = false;
    state.error = error;
  },

  [CREATE_TEAM_SUCCESS](state, { team }) {
    Vue.set(state.teams, team.id, team);
    state.loading = false;
  },

  [LEAVE_TEAM_REQUEST](state) {
    state.loading = true;
    state.error = null;
  },

  [LEAVE_TEAM_FAILURE](state, { error }) {
    state.loading = false;
    state.error = error;
  },

  [LEAVE_TEAM_SUCCESS](state, { teamId }) {
    Vue.delete(state.teams, teamId);
    state.loading = false;
  },

  [FETCH_TEAMS_REQUEST](state) {
    state.loading = true;
    state.error = null;
  },

  [FETCH_TEAMS_SUCCESS](state, { teamsInfo }) {
    state.loading = false;
    state.error = null;

    state.totalTeams = teamsInfo.total;
    Vue.set(state, "teams", teamsInfo.data);
  },

  [FETCH_TEAMS_FAILURE](state, { error }) {
    state.loading = false;
    state.error = error;
  },

  [FETCH_USERS_REQUEST](state) {
    state.loading = true;
    state.error = null;
  },

  [FETCH_USERS_SUCCESS](state, { users, teamId }) {
    state.loading = false;
    state.error = null;

    Vue.set(state.users, teamId, users);
  },

  [FETCH_USERS_FAILURE](state, { error }) {
    state.loading = false;
    state.error = error;
  },

  [RESET_STATE](state) {
    const initialState = {
      loading: false,
      error: null,
      teams: [],
      totalTeams: null,
      users: {},
    };
    Object.assign(state, initialState);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
