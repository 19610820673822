import Vue from "vue";
import Vuex from "vuex";
import createLogger from "vuex/dist/logger";

import state from "./store/state";
import getters from "./store/getters";
import actions from "./store/actions";
import mutations from "./store/mutations";

import teams from "./store/modules/teams";
import statistics from "./store/modules/statistics";
import payment from "./store/modules/payment";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  strict: debug,
  plugins: debug ? [createLogger()] : [],
  modules: {
    teams,
    statistics,
    payment,
  },
  state,
  getters,
  actions,
  mutations,
});
